import { useLocation } from 'react-router-dom';

import { useBinding } from '@/hooks';

import { Header } from './Header';

export function HeaderContainer() {
  const location = useLocation();
  const { quote } = useBinding();

  const hideQuoteId = location.pathname === '/';
  const quoteId = quote?._id || undefined;

  return <Header quoteId={quoteId} hideQuoteId={hideQuoteId} />;
}
