import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Button } from '@mui/material';

import { ButtonLink } from '@/components/ButtonLink';
import { Image } from '@/components/Image';

import coverImage from './customer_touchpoint.png';

export function CustomerTouchpointPage() {
  return (
    <Box
      position="absolute"
      top="0"
      left="0"
      width="100%"
      height="100%"
      bgcolor="grey.00"
    >
      <Image src={coverImage} width="100%" />
      <Box position="absolute" left="37.35vw" top="0" paddingTop="28%">
        <ButtonLink
          to="/"
          color="primary"
          endIcon={<OpenInNewIcon />}
          size="large"
          variant="contained"
          sx={{
            borderRadius: 10,
            minWidth: '10vw',
            fontSize: 'calc(1px + 1vw)',
            minHeight: '0',
            padding: '0.25vw 1.25vw',
            ':hover': {
              backgroundColor: 'primary.main',
            },
          }}
        >
          <strong>View Policy</strong>
        </ButtonLink>
      </Box>
    </Box>
  );
}
