import { useCallback, useEffect, useState } from 'react';

import { delay } from '../helpers';

interface IArgs<Res, Err> {
  mockResponse?: Res;
  mockError?: Err;
  mockDelay?: number;
  mockVariables?: any;
}

export function useMockDataQuery<Res, Err>({
  mockDelay = 500,
  mockError,
  mockResponse,
  mockVariables,
}: IArgs<Res, Err>) {
  const [data, setData] = useState<Res | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<Err | null>(null);

  useEffect(() => {
    async function fetchData() {
      await delay(mockDelay);

      if (mockError) {
        setData(null);
        setError(mockError);
      } else {
        setData(mockResponse || null);
        setError(null);
      }
    }

    setLoading(true);

    fetchData().finally(() => setLoading(false));
  }, [mockDelay, mockError, mockResponse, mockVariables]);

  const refetch = useCallback(() => {
    setData(null);
    setError(null);
    setLoading(false);
  }, []);

  return {
    data,
    error,
    isLoading,
    refetch,
  };
}
