import { SerializedError } from '@reduxjs/toolkit';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { QueryReturnValue } from '@reduxjs/toolkit/src/query/baseQueryTypes';
import { Mutex } from 'async-mutex';
import { DocumentNode } from 'graphql';
import { ClientError } from 'graphql-request';

import { AuthorizeMutation } from '@/graphql/generated';
import {
  checkUnauthorizedError,
  getGraphqlErrorsFromMeta,
  IMetaExtras,
} from '@/helpers';

import { api as apiSlice } from '../api';
import { login, logout } from '../auth';
import { RootState } from '../configureStore';

import { getAuthorizeArgs } from './authorizeArgs';
import { baseQuery } from './baseQuery';

// Create a lock during a reeust
// https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#automatic-re-authorization-by-extending-fetchbasequery
const mutex = new Mutex();

export const graphqlRequestQuery: BaseQueryFn<
  { document: string | DocumentNode; variables?: unknown },
  unknown,
  Pick<SerializedError, 'name' | 'message' | 'stack' | 'graphQLErrors'>,
  Partial<Pick<ClientError, 'request' | 'response'>>
> = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();

  // Make first attempt request
  let result = await baseQuery(args, api, extraOptions);

  // Check for errors
  if (result.error) {
    const errors = getGraphqlErrorsFromMeta(result.meta as IMetaExtras);

    // Stuff graphql errors into the error object
    (result.error as SerializedError).graphQLErrors = errors;

    const hasUnauthorizedError = checkUnauthorizedError(errors);

    // Check if token needs to be refreshed
    if (hasUnauthorizedError) {
      // Check weather mutex is locked
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();

        try {
          const oldToken = (api.getState() as RootState).auth.token;

          let reauthResult:
            | QueryReturnValue<
                unknown,
                Pick<ClientError, 'name' | 'message' | 'stack'>,
                Record<string, unknown>
              >
            | undefined;

          if (oldToken) {
            // refresh token request
            reauthResult = await baseQuery(
              getAuthorizeArgs(oldToken),
              api,
              extraOptions,
            );
          }

          const data = reauthResult?.data as AuthorizeMutation;

          // Update token
          if (data?.authorize) {
            // Save token in store and re-set client header
            api.dispatch(login(data.authorize));

            // retry request
            result = await baseQuery(args, api, extraOptions);
          } else {
            api.dispatch(logout());
            api.dispatch(apiSlice.util.resetApiState());
          }
        } finally {
          release();
        }

        // If mutex is locked, wait until it is unlocked
        // the refresh request is already in progress
        // and a new token will be requested if successful
        // and mutex unlocked (regardless if successful or not)
      } else {
        await mutex.waitForUnlock();

        // make second attempt request since mutex is unlocked
        // and new token is in place (or request will fail normally after logout)
        result = await baseQuery(args, api, extraOptions);
      }
    }
  }

  return result;
};
