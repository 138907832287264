import { Box, BoxProps, Typography } from '@mui/material';

import { SVG } from '@/components/SVGs/SVG';
import { FULLSCREEN_LOADER_Z_INDEX } from '@/config/zIndexes';

interface IProps {
  children?: React.ReactNode;
  sticky?: boolean;
  visible?: boolean;
}

export function ContainerLoader({
  children,
  sticky = false,
  visible = true,
  ...boxProps
}: IProps & BoxProps) {
  return (
    <Box
      {...boxProps}
      sx={{
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.75)',
        bottom: 0,
        display: visible ? 'flex' : 'none',
        flexDirection: 'column',
        justifyContent: sticky ? 'unset' : 'center',
        left: 0,
        position: 'absolute',
        top: 0,
        width: '100%',
        zIndex: FULLSCREEN_LOADER_Z_INDEX,
        ...boxProps.sx,
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '30% 0',
          position: sticky ? 'sticky' : 'unset',
          top: 0,
        }}
      >
        <SVG name="AnimatedLoader" height="100px" width="100px" />
        <Typography>{children}</Typography>
      </Box>
    </Box>
  );
}
