import { useState, useCallback } from 'react';

import { SignatureReceivedModal } from '@/components/Modals/SignatureReceivedModal';
import { useMockDataMutation } from '@/hooks';

import { File as UploadedFile } from '../../graphql/generated';

import { CustomerSignaturePage } from './CustomerSignaturePage';

export const NEXT_PAGE_ROUTE = 'https://ascend.org/loan-tools/pay';

const mockResponse = {
  _id: '12345678',
  bucketName: 'mockBucketName',
  signatureImage: 'client-signature.png',
  filepath: '/mockBucketName/12345678/client-signature.png',
} as UploadedFile;

export function CustomerSignaturePageContainer() {
  const [signatureReceivedOpen, setSignatureReceivedOpen] = useState(false);

  const [mutate, { isLoading }] = useMockDataMutation({
    mockDelay: 500,
    mockResponse: mockResponse,
  });

  const onSubmitHandler = useCallback(
    async (signatureImageBase64: string) => {
      const result = await mutate(signatureImageBase64);
      console.log('result', result);

      setSignatureReceivedOpen(true);
    },
    [mutate],
  );

  const closeReceivedModal = useCallback(() => {
    setSignatureReceivedOpen(false);

    window.location.href = NEXT_PAGE_ROUTE;
  }, [setSignatureReceivedOpen]);

  return (
    <>
      <CustomerSignaturePage isLoading={isLoading} onSubmit={onSubmitHandler} />
      <SignatureReceivedModal
        open={signatureReceivedOpen}
        onClose={closeReceivedModal}
      />
    </>
  );
}
