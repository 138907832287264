import { CssBaseline, Theme, ThemeProvider } from '@mui/material';
import { EnhancedStore } from '@reduxjs/toolkit';
import { StrictMode, Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { theme as defaultTheme } from '@/config';
import { RootState, store as defaultStore } from '@/store/configureStore';

import { Initializer } from '../Initializer';
import { Router } from '../Router';

interface IProps {
  store?: EnhancedStore<RootState>;
  theme?: Theme;
}

export function App({ store = defaultStore, theme = defaultTheme }: IProps) {
  return (
    <Suspense fallback="Loading">
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Initializer>
            <BrowserRouter>
              <Router />
            </BrowserRouter>
          </Initializer>
        </ThemeProvider>
      </Provider>
    </Suspense>
  );
}

export const StrictModeApp = () => (
  <StrictMode>
    <App />
  </StrictMode>
);
