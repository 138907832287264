import { addYears } from 'date-fns';
import { useEffect, useMemo } from 'react';

import { HybridHomeownersQuote } from '@/graphql/generated';
import { mockResponse } from '@/helpers/mocks/contracts';
import { useBinding, useMockDataQuery } from '@/hooks';

import { LandingPage } from './LandingPage';

export function LandingPageContainer() {
  const { data, isLoading, error } = useMockDataQuery({
    mockDelay: 3000,
    mockResponse: mockResponse,
  });

  const { setQuote } = useBinding();

  useEffect(() => {
    if (data?.getCustomerContract?.quotes?.[0]) {
      setQuote(data?.getCustomerContract?.quotes?.[0]);
    }
  }, [data, setQuote]);

  const landingPageProps = useMemo(() => {
    const client = data?.getCustomerContract.customerCounterparty;
    const quote = data?.getCustomerContract.quotes[0] as
      | HybridHomeownersQuote
      | undefined;

    const quoteId = quote?._id || undefined;
    const address = quote?.coverageStructure?.property?.address || undefined;

    // Dates
    const startDateISO = quote?.coverageStructure?.startDate;
    const startDate = startDateISO ? new Date(startDateISO) : undefined;
    const endDate = startDate ? addYears(startDate, 1) : undefined;

    const prices = quote?.prices;
    const fullPremium = prices?.fullPremium?.vector || undefined;
    const monthlyPremium = prices?.monthlyPremium?.vector || undefined;

    const name = client?.profile?.firstName || undefined;

    return {
      address,
      endDate,
      fullPremium,
      monthlyPremium,
      name,
      quoteId,
      startDate,
    };
  }, [data]);

  return (
    <LandingPage
      isLoading={isLoading}
      address={landingPageProps?.address}
      endDate={landingPageProps?.endDate}
      fullPremium={landingPageProps?.fullPremium}
      monthlyPremium={landingPageProps?.monthlyPremium}
      name={landingPageProps?.name}
      quoteId={landingPageProps?.quoteId}
      startDate={landingPageProps?.startDate}
    />
  );
}
