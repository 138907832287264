import { Fragment, useEffect, useState } from 'react';

import { FullscreenLoader } from '@/components/Loaders';
import { useLazyCurrentUserQuery } from '@/store/api';
import { store } from '@/store/configureStore';

interface IProps {
  children: React.ReactNode;
}

export function Initializer({ children }: IProps) {
  const [initialized, setInitialized] = useState(
    // if we aren't missing information
    // from the server, we can assume
    // the app is initialized
    () => !store.getState().auth.awaitingCurrentUserQuery,
  );

  const [query, { isLoading }] = useLazyCurrentUserQuery();

  useEffect(() => {
    // Grab auth check from store directly
    const awaitingCurrentUserQuery =
      store.getState().auth.awaitingCurrentUserQuery;

    if (!initialized && awaitingCurrentUserQuery) {
      query({});
    }

    setInitialized(true);
  }, [initialized, query]);

  return isLoading || !initialized ? (
    <FullscreenLoader>Loading ...</FullscreenLoader>
  ) : (
    <Fragment>{children}</Fragment>
  );
}
