import { Box, BoxProps, Typography } from '@mui/material';

import { FontWeight } from '@/config/theme/typography';

interface IProps extends BoxProps {
  children?: React.ReactNode;
  title?: string;
}

export function PageTitle({ children, title, ...boxProps }: IProps) {
  return (
    <Box mx="auto" textAlign="center" {...boxProps}>
      <Typography
        fontSize="1.75rem"
        fontWeight={FontWeight.semibold}
        variant="h1"
      >
        {title}
      </Typography>
      <Typography color="secondary" mt={2} sx={{ lineHeight: 1.5 }}>
        {children}
      </Typography>
    </Box>
  );
}
