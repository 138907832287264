import { Box, BoxProps, Skeleton, Stack, Typography } from '@mui/material';

import { FontWeight } from '@/config/theme/typography';

import { HorizontalRuler } from '../HorizontalRuler';

interface IProps extends BoxProps {
  description?: string;
  hideBottomBorder?: boolean;
  isLoading?: boolean;
  label?: string;
  value?: string;
}

export function Row({
  description,
  hideBottomBorder,
  isLoading,
  label = '---',
  value,
  ...props
}: IProps) {
  return (
    <Box {...props}>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        mb={1.25}
      >
        <Typography
          sx={{
            fontSize: '1.0625rem',
            fontWeight: FontWeight.medium,
            color: 'grey.90',
          }}
        >
          {label}
        </Typography>
        {isLoading ? (
          <Skeleton width="200px" height="1.65rem" />
        ) : (
          <Typography
            sx={{
              fontSize: '1.3125rem',
              fontWeight: FontWeight.semibold,
              minHeight: '1.65rem',
              color: 'grey.90',
            }}
          >
            {value}
          </Typography>
        )}
      </Stack>
      {
        <Typography
          sx={{
            fontSize: '0.9375rem',
            fontWeight: FontWeight.regular,
            color: 'grey.70',
            maxWidth: '658px',
            mb: description ? 3 : 2,
          }}
        >
          {description}
        </Typography>
      }
      {!hideBottomBorder && <HorizontalRuler mb={2.5} />}
    </Box>
  );
}
