import { Property, User } from '@/graphql/generated';

export function fullNameSelector(state?: User | null, fallback = '') {
  if (!state?.profile?.firstName && !state?.profile?.lastName) {
    return fallback;
  }

  return `${state?.profile?.firstName || fallback} ${
    state?.profile?.lastName || fallback
  }`;
}

export function addressSelector(state?: User): Property['address'] | undefined {
  if (
    !state?.profile?.address1 &&
    !state?.profile?.city &&
    !state?.profile?.state &&
    !state?.profile?.zipcode
  ) {
    return undefined;
  }

  return {
    address1: state?.profile?.address1 || undefined,
    address2: state?.profile?.address2 || undefined,
    city: state?.profile?.city || undefined,
    state: state?.profile?.state || undefined,
    zipcode: state?.profile?.zipcode || undefined,
  };
}
