import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion as MUIAccodion,
  AccordionProps,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';
import Typography from '@mui/material/Typography';

interface IProps extends AccordionProps {
  title?: string;
}

export function Accordion({ title, children, ...rest }: IProps) {
  return (
    <MUIAccodion
      {...rest}
      sx={{
        ...rest.sx,
        borderTop: '1px solid',
        borderColor: 'grey.30',
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography
          variant="h2"
          sx={{
            color: 'grey.100',
            '&:hover': {
              color: 'success.main',
            },
          }}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </MUIAccodion>
  );
}
