import { useTranslation } from 'react-i18next';

import { Button } from '../Button';

import { BaseModal } from './BaseModal';

interface IProps {
  hideCloseButton?: boolean;
  onClose?: () => void;
  open: boolean;
}

export function SignatureReceivedModal({
  hideCloseButton = true,
  onClose,
  open,
}: IProps) {
  const { t } = useTranslation();

  return (
    <BaseModal
      hideCloseButton={hideCloseButton}
      onClose={onClose}
      open={open}
      title={t(
        'pages.customerSignaturePage.signatureReceived',
        'Signature Received',
      )}
      PaperProps={{
        sx: {
          width: '450px',
        },
      }}
      actionBar={
        <Button
          color="secondary"
          onClick={onClose}
          variant="contained"
          sx={{ width: '152px' }}
        >
          {t('common.actions.continue', 'Continue')}
        </Button>
      }
    >
      {t(
        'modals.signatureReceivedModal.sendToClient',
        'Next you’ll be directed to select and setup your preferred payment method',
      )}
    </BaseModal>
  );
}
