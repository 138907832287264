import { Box, Typography } from '@mui/material';

export function NotFoundPage() {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        backgroundColor: 'common.white',
        padding: 4,
      }}
    >
      <Typography
        fontSize="calc(1.5rem + 2vw)"
        sx={{ writingMode: 'vertical-rl', textOrientation: 'upright' }}
      >
        404 -{' '}
      </Typography>
    </Box>
  );
}
