import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { QuotePreviewCard } from '@/components/Cards/QuotePreviewCard';
import { PageTitle } from '@/components/PageTitle';
import { FontWeight } from '@/config/theme/typography';
import { PropertyQuoteDetails } from '@/graphql/generated';

interface IProps {
  address?: PropertyQuoteDetails['address'];
  endDate?: Date;
  fullPremium?: string | number;
  isLoading?: boolean;
  monthlyPremium?: string | number;
  name?: string;
  quoteId?: string;
  startDate?: Date;
}

export function LandingPage({
  address,
  endDate,
  fullPremium,
  isLoading,
  monthlyPremium,
  name,
  quoteId,
  startDate,
}: IProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const title = t(
    'pages.landingPage.title',
    "Hi {{name}}, Here's your quote to review",
    { name },
  );

  return (
    <Box mt={5}>
      <Box maxWidth="259px" mx="auto">
        <PageTitle>
          <Typography fontSize="1.3125rem" fontWeight={FontWeight.semibold}>
            {title}
          </Typography>
        </PageTitle>
      </Box>
      <Box mt={5} display="flex" justifyContent="center">
        <QuotePreviewCard
          isLoading={isLoading}
          quoteId={quoteId}
          address={address}
          startDate={startDate}
          endDate={endDate}
          fullPremium={fullPremium}
          monthlyPremium={monthlyPremium}
          onClick={() => navigate('/review-quote')}
        />
      </Box>
    </Box>
  );
}
