import { useTranslation } from 'react-i18next';

import { Accordion } from '../Accordion';

import { Disclosure } from './Disclosure';

export function Footer() {
  const { t } = useTranslation();

  return (
    <Accordion
      title={t('common.labels.disclosures', 'Disclosures')}
      sx={{
        boxShadow: 'none',
        '&:before': {
          display: 'none',
        },
        '&:last-of-type': {
          borderBottomLeftRadius: '16px',
          borderBottomRightRadius: '16px',
          marginTop: 0,
        },
        '& .MuiAccordionSummary-root': {
          marginLeft: 3,
          paddingRight: 3,
          paddingLeft: 0,
        },
        '& .MuiAccordionSummary-root.Mui-expanded': {
          borderBottomWidth: '1px',
          borderStyle: 'solid',
          borderColor: 'grey.30',
        },
        '& .MuiAccordionSummary-content': {
          marginY: 3,
        },
      }}
    >
      <Disclosure />
    </Accordion>
  );
}
