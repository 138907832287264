import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { FontWeight } from '@/config/theme/typography';

import { HEADER_MAX_WIDTH } from '../Header/Header';
import { SVG } from '../SVGs/SVG';

interface IProps {
  onBack?: () => void;
  children?: React.ReactNode;
  hideLeftArrow?: boolean;
}

export function FooterActionBar({ children, onBack, hideLeftArrow }: IProps) {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        borderTop: '1px solid',
        borderColor: 'grey.50',
        mx: 'auto',
        maxWidth: HEADER_MAX_WIDTH,
        mt: 5,
        py: 2,
        pb: 5,
        width: '100%',
      }}
    >
      <Button
        onClick={onBack}
        sx={{
          color: 'secondary.main',
          fontSize: '1.3125rem',
          fontWeight: FontWeight.medium,
          px: 2,
        }}
        startIcon={
          !hideLeftArrow && (
            <SVG
              name="LeftArrow"
              sx={{
                color: 'secondary.main',
                height: '18px',
                mr: 0.25,
                width: '12px',
              }}
            />
          )
        }
      >
        {t('common.actions.back', 'Back')}
      </Button>
      {children}
    </Box>
  );
}
