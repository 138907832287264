import { Box, Typography } from '@mui/material';
import { formatDistanceStrict } from 'date-fns';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FontWeight } from '@/config/theme/typography';
import { PropertyQuoteDetails } from '@/graphql/generated';
import {
  formatAddress,
  formatCurrency,
  formatDate,
} from '@/helpers/formatters';

import { Footer } from './Footer';
import { Header } from './Header';
import { Row } from './Row';
import { percentageOfFormatter } from './helpers';

interface IProps {
  address?: PropertyQuoteDetails['address'];
  allOtherPerils?: string | number;
  carportYearBuilt?: string | number;
  carportAmount?: string | number;
  coverageI?: string | number;
  coverageII?: string | number;
  coverageIII?: string | number;
  coverageIV?: string | number;
  coverageV?: string | number;
  coverageVI?: string | number;
  endDate?: Date;
  fullPremium?: string | number;
  hurricane?: string | number;
  isLoading?: boolean;
  mold?: string | number;
  monthlyPremium?: string | number;
  name?: string;
  parametricExpenses?: string | number;
  startDate?: Date;
}

export function QuoteSummary({
  address,
  allOtherPerils,
  carportYearBuilt,
  carportAmount,
  coverageI,
  coverageII,
  coverageIII,
  coverageIV,
  coverageV,
  coverageVI,
  parametricExpenses,
  hurricane,
  isLoading,
  mold,
  name,
  startDate,
}: IProps) {
  const { t } = useTranslation();

  const formattedAddress = address ? formatAddress(address) : '';
  const startDateFormatted = startDate
    ? formatDate(startDate, 'FULL_MONTH_DAY_FULL_YEAR')
    : '';

  const carpotAge = useMemo(() => {
    if (!carportYearBuilt) {
      return '';
    }

    return formatDistanceStrict(
      new Date(Number(carportYearBuilt), 0, 1),
      new Date(),
    );
  }, [carportYearBuilt]);

  const coverageIFormatted = formatCurrency(coverageI);

  const coverageIVFormatted = formatCurrency(coverageIV);
  const coverageVFormatted = formatCurrency(coverageV);
  const coverageVIFormatted = formatCurrency(coverageVI);
  const parametricExpensesFormatted = formatCurrency(parametricExpenses);

  const allOtherPerilsFormatted = formatCurrency(allOtherPerils);
  const carportAmountFormatted = formatCurrency(carportAmount);
  const hurricaneFormatted = formatCurrency(hurricane);
  const moldFormatted = formatCurrency(mold);

  // percentage of the coverageA
  const coverageIIFormatted = percentageOfFormatter(coverageII, coverageI);
  const coverageIIIFormatted = percentageOfFormatter(coverageIII, coverageI);

  return (
    <Box
      bgcolor="grey.00"
      sx={{
        borderRadius: 4,
      }}
    >
      <Header
        address={formattedAddress}
        name={name}
        startDate={startDateFormatted}
      />
      {/* Body */}
      <Box px={4} py={3} bgcolor="grey.10">
        <Typography
          my={4}
          fontSize="1.3125rem"
          fontWeight={FontWeight.semibold}
        >
          Coverage selections
        </Typography>
        <Row
          isLoading={isLoading}
          label={t(
            'common.coverageI.label',
            'Dwelling / Main Home / Coverage I',
          )}
          description={t(
            'common.coverageI.description',
            'Dwelling insurance covers damage to your home’s main structure. Your structure is insured against most events, but not against any named exclusions. Unlike traditional HO-3 policies, this policy does not cover your home’s roof when damaged as a result of weather.',
          )}
          value={coverageIFormatted}
        />
        <Row
          isLoading={isLoading}
          label={t('common.coverageII.label', 'Other structure / Coverage II')}
          description={t(
            'common.coverageII.description',
            'Other Structures insurance covers any additional structures on your property like garages and fences. Your structure is insured against most events, but not against any named exclusions.',
          )}
          value={coverageIIFormatted}
        />
        <Row
          isLoading={isLoading}
          label={t(
            'common.coverageIII.label',
            'Personal Property / Coverage III',
          )}
          description={t(
            'common.coverageIII.description',
            'Personal Property insurance protects your belongings when stolen, lost, or damaged as a result of only the perils listed in your policy.',
          )}
          value={coverageIIIFormatted}
        />
        <Row
          isLoading={isLoading}
          label={t('common.coverageIV.label', 'Loss of Use/ Coverage IV')}
          description={t(
            'common.coverageIV.description',
            'Loss of Use insurance pays any necessary increase in living expenses incurred as a result of a covered loss that makes your home not fit for living.',
          )}
          value={coverageIVFormatted}
        />
        <Row
          isLoading={isLoading}
          label={t('common.coverageV.label', 'Personal Liability / Coverage V')}
          description={t(
            'common.coverageV.description',
            'Personal Liability insurance pays in the event that a claim is made against you due to bodily injury or property damage that occurs on your property.',
          )}
          value={coverageVFormatted}
        />
        <Row
          isLoading={isLoading}
          label={t(
            'common.coverageVI.label',
            'Medical Payments to Others / Coverage VI',
          )}
          description={t(
            'common.coverageVI.description',
            'Medical Payments to Others insurance pays necessary medical expenses incurred as a result of bodily injury.',
          )}
          value={coverageVIFormatted}
        />
        <Row
          isLoading={isLoading}
          label={t('common.parametricExpense.label', 'Parametric Expense')}
          description={t(
            'common.parametricExpense.description',
            'Additional Hurricane Parametric Expense insurance pays expenses incurred resulting from a hurricane. This is a parametric coverage link. When applicable, loss is calculated based on the distance of the hurricane from your home and on wind speed.',
          )}
          value={parametricExpensesFormatted}
        />
        <Row
          isLoading={isLoading}
          label={t('common.allOtherPerils.label', 'All Other Perils')}
          value={allOtherPerilsFormatted}
        />
        <Row
          isLoading={isLoading}
          label={t('common.hurricane.label', 'Hurricane')}
          value={hurricaneFormatted}
        />
        <Row
          isLoading={isLoading}
          label={t('common.mold.label', 'Mold')}
          value={moldFormatted}
        />
        <Row
          isLoading={isLoading}
          label={t('common.carportAmount.label', 'Carport Amount')}
          value={carportAmountFormatted}
        />
        <Row
          isLoading={isLoading}
          label={t('common.carportAge.label', 'Carport Age')}
          hideBottomBorder
          value={carpotAge}
        />
      </Box>
      <Footer />
    </Box>
  );
}
