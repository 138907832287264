import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { HybridHomeownersQuote } from '@/graphql/generated';

export interface IBindingState {
  signatureBase64?: string;
  quote?: HybridHomeownersQuote;
}

export const initialBindingState: IBindingState = {
  signatureBase64: undefined,
  quote: undefined,
};

export const bindingSlice = createSlice({
  name: 'binding',
  initialState: { ...initialBindingState },
  reducers: {
    reset: () => ({ ...initialBindingState }),
    set: (state, { payload }: PayloadAction<IBindingState>) => ({
      ...state,
      ...payload,
    }),
    setQuote: (state, { payload }: PayloadAction<HybridHomeownersQuote>) => {
      state.quote = payload;
    },
    setSignatureBase64: (state, { payload }: PayloadAction<string>) => {
      state.signatureBase64 = payload;
    },
  },
});

export const { reset, set, setQuote, setSignatureBase64 } =
  bindingSlice.actions;
