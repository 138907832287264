import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { FontWeight } from '@/config/theme/typography';
import { PropertyQuoteDetails } from '@/graphql/generated';
import {
  formatAddress,
  formatCurrency,
  formatDate,
} from '@/helpers/formatters';

import { Button } from '../Button';
import { InfoRow } from '../InfoBoxes/InfoRow';
import { ContainerLoader } from '../Loaders';

interface IProps {
  address?: PropertyQuoteDetails['address'];
  endDate?: Date;
  fullPremium?: string | number;
  isLoading?: boolean;
  monthlyPremium?: string | number;
  onClick?: () => void;
  quoteId?: string;
  startDate?: Date;
}

export function QuotePreviewCard({
  address,
  endDate,
  fullPremium,
  isLoading,
  monthlyPremium,
  onClick,
  quoteId,
  startDate,
}: IProps) {
  const { t } = useTranslation();

  const formattedAddress = address ? formatAddress(address) : '---';

  const startDateFormatted = startDate
    ? formatDate(startDate, 'SHORT_MONTH_DAY_FULL_YEAR')
    : '---';
  const endDateFormatted = endDate
    ? formatDate(endDate, 'SHORT_MONTH_DAY_FULL_YEAR')
    : '---';

  const formattedFullPremium = formatCurrency(fullPremium) || '---';
  const formattedMonthlyPremium = formatCurrency(monthlyPremium) || '---';
  const termLength = 12;

  return (
    <Box bgcolor={'grey.00'} borderRadius={3} position="relative" width="450px">
      {isLoading && (
        <ContainerLoader sx={{ borderRadius: 3 }}>
          {t(
            'components.quotePreviewCard.loading',
            'Fetching quote details...',
          )}
        </ContainerLoader>
      )}
      <Box px={3} py={1.5} borderBottom="1px solid" borderColor="grey.30">
        <Typography fontSize="0.9375rem" fontWeight={FontWeight.semibold}>
          Quote ID: {quoteId || '---'}
        </Typography>
      </Box>
      <Box px={3} py={3}>
        <Stack spacing={3}>
          <InfoRow
            label={t(
              'components.quotePreviewCard.address',
              'Address being insured',
            )}
          >
            {formattedAddress}
          </InfoRow>
          <InfoRow
            label={t(
              'components.quotePreviewCard.policyPeriod',
              'Policy period',
            )}
            size="small"
          >
            {`${startDateFormatted} - ${endDateFormatted}`}
          </InfoRow>
          <InfoRow
            label="Premium"
            subtext={t(
              'components.quotePreviewCard.monthlyPremium',
              `or pay {{premium}}/month for {{termLength}} months`,
              { premium: formattedMonthlyPremium, termLength },
            )}
            tooltip={t(
              'components.quotePreviewCard.tooltip',
              'This is the total premium for the policy period. You can pay the full premium up front or pay monthly for the policy term.',
            )}
          >
            {formattedFullPremium}
          </InfoRow>
        </Stack>
        <Box display="flex" mt={3} justifyContent="flex-end">
          <Button
            onClick={onClick}
            color="secondary"
            size="small"
            variant="contained"
            sx={{ fontSize: '1rem' }}
          >
            {t('components.quotePreviewCard.continue', 'Review Quote')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
