import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { HybridHomeownersQuote } from '@/graphql/generated';
import { reset, setQuote, setSignatureBase64 } from '@/store/binding';
import { RootState } from '@/store/configureStore';

export function useBinding() {
  const dispatch = useDispatch();

  const resetHandler = useCallback(() => {
    dispatch(reset());
  }, [dispatch]);

  const setQuoteHandler = useCallback(
    (quote: HybridHomeownersQuote) => {
      dispatch(setQuote(quote));
    },
    [dispatch],
  );

  const setsignatureBase64Handler = useCallback(
    (signatureBase64: string) => {
      dispatch(setSignatureBase64(signatureBase64));
    },
    [dispatch],
  );

  return {
    quote: useSelector((state: RootState) => state.binding.quote),
    reset: resetHandler,
    setQuote: setQuoteHandler,
    setSignature: setsignatureBase64Handler,
    signatureBase64: useSelector(
      (state: RootState) => state.binding.signatureBase64,
    ),
  };
}
