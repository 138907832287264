import {
  Components,
  inputLabelClasses,
  outlinedInputClasses,
} from '@mui/material';

import { baseTheme } from './base';
import { muiAutocompleteOverrides } from './overrides';

const paperBoxShadow =
  '0px 100px 80px rgba(0, 0, 0, 0.04), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0287542), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0238443), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.02), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0161557), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0112458)';

export const components: Components = {
  MuiAutocomplete: {
    styleOverrides: {
      // order matter here
      inputRoot: muiAutocompleteOverrides.inputRoot,
      paper: muiAutocompleteOverrides.paper,
      popper: muiAutocompleteOverrides.popper,
    },
  },
  MuiCssBaseline: {
    styleOverrides: `
      #root {
        min-height: 100vh;
        position: relative;
      }
    `,
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
      disableFocusRipple: true,
      disableRipple: true,
      disableTouchRipple: true,
    },
    variants: [
      {
        props: { variant: 'dashed' },
        style: {
          textTransform: 'none',
          border: `2px dashed ${baseTheme.palette.primary.main}`,
          color: baseTheme.palette.primary.main,
        },
      },
    ],
    styleOverrides: {
      containedSecondary: {
        '&:disabled': {
          backgroundColor: baseTheme.palette.grey[40],
        },
      },
      contained: {
        color: 'white',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        borderRadius: baseTheme.spacing(1),
      },
      outlined: {
        borderRadius: baseTheme.spacing(1),
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        padding: baseTheme.spacing(3),
        borderRadius: baseTheme.spacing(),
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        color: baseTheme.palette.grey[70],
      },
      outlined: {
        color: baseTheme.palette.grey[70],
        lineHeight: '1.45',

        [`&.${inputLabelClasses.focused}`]: {
          color: baseTheme.palette.grey[70],
        },

        [`&.${inputLabelClasses.shrink}`]: {
          transform: 'translate(14px, 5px) scale(0.8125)',
        },
      },
    },
  },
  MuiOutlinedInput: {
    defaultProps: {
      notched: false,
    },
    styleOverrides: {
      root: {
        backgroundColor: 'white',
        borderRadius: baseTheme.spacing(1),

        [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: baseTheme.palette.grey[60],
        },

        [`&.${outlinedInputClasses.focused}`]: {
          [`.${outlinedInputClasses.notchedOutline}`]: {
            borderWidth: '2px',
            borderColor: baseTheme.palette.success.light,
          },
        },
      },
      input: {
        fontWeight: 600,
        padding: '24px 14px 10px 14px',
      },
      notchedOutline: {
        borderColor: baseTheme.palette.grey[30],
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        borderRadius: baseTheme.spacing(2),
      },
      elevation: {
        boxShadow: paperBoxShadow,
      },
      elevation0: {
        boxShadow: 'none',
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: baseTheme.palette.secondary.main,
        borderRadius: '6px',
        boxShadow:
          '0px 41px 117px rgba(0, 0, 0, 0.07), 0px 17.1288px 48.8798px rgba(0, 0, 0, 0.0503198), 0px 9.15789px 26.1335px rgba(0, 0, 0, 0.0417275), 0px 5.13384px 14.6502px rgba(0, 0, 0, 0.035), 0px 2.72654px 7.78062px rgba(0, 0, 0, 0.0282725), 0px 1.13458px 3.23769px rgba(0, 0, 0, 0.0196802)',
        fontSize: '0.95rem',
        lineHeight: '20px',
        maxWidth: '459px',
        padding: baseTheme.spacing(2),
        paddingTop: baseTheme.spacing(1.5),
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      root: {
        letterSpacing: 'normal',
      },
    },
  },
};
