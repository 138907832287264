import { createApi } from '@reduxjs/toolkit/query/react';

import {
  AuthorizeMutation,
  AuthorizeMutationVariables,
  CurrentUserQuery,
  CurrentUserQueryVariables,
} from '@/graphql/generated';

import { CacheTag } from './CacheTags';
import { authorizeEndpoint, currentUserEndpoint } from './endpoints';
import { graphqlRequestQuery } from './graphqlRequestQuery';

export const api = createApi({
  baseQuery: graphqlRequestQuery,
  reducerPath: 'api',
  tagTypes: [...Object.values(CacheTag)],
  endpoints: (builder) => ({
    authorize: builder.mutation<AuthorizeMutation, AuthorizeMutationVariables>({
      ...authorizeEndpoint,
    }),
    currentUser: builder.query<CurrentUserQuery, CurrentUserQueryVariables>({
      ...currentUserEndpoint,
    }),
  }),
});
