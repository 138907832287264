import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { HeaderInfo } from './HeaderInfo';

interface IProps {
  address?: string;
  name?: string;
  startDate?: string;
}

export function Header({ name, address, startDate }: IProps) {
  const { t } = useTranslation();

  return (
    <Box borderBottom="1px solid" borderColor="grey.30" sx={{ py: 3, px: 8 }}>
      <Stack alignItems="center" justifyContent="space-between" direction="row">
        <HeaderInfo
          label={t('components.quoteSummary.nameLabel', 'Name on Policy')}
          value={name}
        />
        <HeaderInfo
          label={t(
            'components.quoteSummary.addressLabel',
            'Address being insured',
          )}
          value={address}
          textAlign="center"
        />
        <HeaderInfo
          label={t('components.quoteSummary.startDate', 'Policy Start Date')}
          value={startDate}
          textAlign="right"
        />
      </Stack>
    </Box>
  );
}
