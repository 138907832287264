import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FooterActionBar } from '@/components/ActionBar/FooterActionBar';
import { Button } from '@/components/Button';
import { PageTitle } from '@/components/PageTitle';
import { QuoteSummary } from '@/components/QuoteSummary/QuoteSummary';
import { PropertyQuoteDetails } from '@/graphql/generated';

interface IProps {
  isLoading?: boolean;

  // Policy Details
  address?: PropertyQuoteDetails['address'];
  name?: string;
  startDate?: Date;

  // Coverage Structure
  coverageI?: string | number;
  coverageII?: string | number;
  coverageIII?: string | number;
  coverageIV?: string | number;
  coverageV?: string | number;
  coverageVI?: string | number;
  parametricExpenses?: string | number;
  allOtherPerils?: string | number;
  hurricane?: string | number;
  mold?: string | number;
  carportYearBuilt?: string | number;
  carportAmount?: string | number;
}

export function ReviewQuotePage({
  address,
  isLoading,
  name,
  startDate,
  allOtherPerils,
  carportYearBuilt,
  carportAmount,
  coverageI,
  coverageII,
  coverageIII,
  coverageIV,
  coverageV,
  coverageVI,
  parametricExpenses,
  hurricane,
  mold,
}: IProps) {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const title = t('pages.reviewQuotePage.title', 'Review & sign');
  const description = t(
    'pages.reviewQuotePage.description',
    'If everything looks correct, please sign below to affix your signature to the policy’s facing page.',
  );

  return (
    <Box mt={5}>
      <Box mx="auto" maxWidth="259px">
        <PageTitle title={title}>{description}</PageTitle>
      </Box>
      <Box
        py={5}
        px={{ xs: 4, md: 0 }}
        display="flex"
        maxWidth="960px"
        margin="auto"
      >
        <QuoteSummary
          isLoading={isLoading}
          address={address}
          name={name}
          startDate={startDate}
          allOtherPerils={allOtherPerils}
          carportYearBuilt={carportYearBuilt}
          carportAmount={carportAmount}
          coverageI={coverageI}
          coverageII={coverageII}
          coverageIII={coverageIII}
          coverageIV={coverageIV}
          coverageV={coverageV}
          coverageVI={coverageVI}
          parametricExpenses={parametricExpenses}
          hurricane={hurricane}
          mold={mold}
        />
      </Box>
      <FooterActionBar onBack={() => navigate('/')} hideLeftArrow>
        <Stack direction="row" spacing={4}>
          <Button
            color="secondary"
            sx={{ color: 'grey.70' }}
            onClick={() => alert("You've requested an edit")}
          >
            Request an edit
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => navigate('/add-signature')}
          >
            Looks good, add signature
          </Button>
        </Stack>
      </FooterActionBar>
    </Box>
  );
}
