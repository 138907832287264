import { Box, Typography, Stack } from '@mui/material';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FooterActionBar } from '@/components/ActionBar/FooterActionBar';
import { Button } from '@/components/Button';
import { HEADER_HEIGHT } from '@/components/Header/Header';
import { PageTitle } from '@/components/PageTitle';
import { SignaturePad } from '@/components/SignaturePad/SignaturePad';
import { useSignaturePad } from '@/components/SignaturePad/useSignaturePad';

export const PREV_PAGE_ROUTE = '/quoter/review-coverage';

interface IProps {
  isLoading: boolean;
  onSubmit: (value: string) => void;
}

export function CustomerSignaturePage({ isLoading, onSubmit }: IProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const signaturePadCanvas = useRef<HTMLCanvasElement>(null);
  const { clear, getDataURL, hasSignature } =
    useSignaturePad(signaturePadCanvas);

  const onSubmitHandler = () => {
    const signatureImage = getDataURL();
    onSubmit?.(signatureImage);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      justifyContent="space-between"
      minHeight={`calc(100vh - ${HEADER_HEIGHT})`}
    >
      <Box>
        <PageTitle
          title={t('pages.customerSignature.title', 'Confirm Signature')}
          pt={5}
        >
          <Typography component="span" sx={{ color: 'grey.80' }}>
            {t(
              'pages.customerSignaturePage.description',
              'Please sign below to affix your signature to the policy’s facing page.',
            )}
          </Typography>
        </PageTitle>
        <Box
          sx={{
            mx: 'auto',
            my: 15.125,
            textAlign: 'center',
            width: '520px',
          }}
        >
          <SignaturePad
            ref={signaturePadCanvas}
            onClear={clear}
            hasSignature={hasSignature}
            isLoading={isLoading}
          />
        </Box>
      </Box>
      <FooterActionBar onBack={() => navigate('/review-quote')}>
        <Stack direction="row" spacing={4}>
          <Button
            color="secondary"
            sx={{ color: 'grey.70' }}
            onClick={() => alert("You've requested an edit")}
          >
            Review
          </Button>
          <Button
            disabled={!hasSignature}
            color="secondary"
            variant="contained"
            onClick={onSubmitHandler}
          >
            Looks good, add signature
          </Button>
        </Stack>
      </FooterActionBar>
    </Box>
  );
}
