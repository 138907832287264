import { Fragment } from 'react';
import { Outlet } from 'react-router-dom';

import { StandardBackground } from '@/components/Backgrounds/StandardBackground';

import { HeaderContainer } from '../Header/HeaderContainer';

export function DefaultLayout() {
  return (
    <Fragment>
      <StandardBackground />
      <HeaderContainer />
      <Outlet />
    </Fragment>
  );
}
