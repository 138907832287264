import { useEffect } from 'react';

import { mockResponse } from '@/helpers/mocks/contracts';
import { fullNameSelector } from '@/helpers/selectors/user';
import { useBinding, useMockDataQuery } from '@/hooks';

import { ReviewQuotePage } from './ReviewQuotePage';

export function ReviewQuotePageContainer() {
  const { data, isLoading, error } = useMockDataQuery({
    mockDelay: 1000,
    mockResponse: mockResponse,
  });

  const { setQuote } = useBinding();

  useEffect(() => {
    if (data?.getCustomerContract?.quotes?.[0]) {
      setQuote(data?.getCustomerContract?.quotes?.[0]);
    }
  }, [data, setQuote]);

  const client = data?.getCustomerContract.customerCounterparty;
  const quote = data?.getCustomerContract.quotes[0];
  const coverStructure = quote?.coverageStructure;

  const startDateISO = quote?.coverageStructure?.startDate;
  const startDate = startDateISO ? new Date(startDateISO) : undefined;

  const address = quote?.coverageStructure?.property?.address || undefined;

  const fullName = fullNameSelector(client, '');

  // Dwelling / Main Home / Coverage I
  const propertyValue = coverStructure?.propertyValue?.vector || undefined;
  const otherStructurePercent =
    coverStructure?.otherStructuresPercentage?.vector || undefined;
  const personalPropertyPercent =
    coverStructure?.personalPropertyPercentage?.vector || undefined;
  const lossOfUse = coverStructure?.lossOfUse?.vector || undefined;
  const personalLiability =
    coverStructure?.personalLiability?.vector || undefined;
  const medicalPayments = coverStructure?.medicalPayments?.vector || undefined;
  const parametricExpenses =
    coverStructure?.roofAdjustmentLimit?.vector || undefined;

  const allOtherPerils = coverStructure?.allOtherPerils?.vector || undefined;
  const hurricane = coverStructure?.hurricaneDeductible?.vector || undefined;
  const mold = coverStructure?.mold?.vector || undefined;
  const carportAmount = coverStructure?.carportAmount || undefined;
  const carportYearBuilt = coverStructure?.carportYearBuilt || undefined;

  return (
    <ReviewQuotePage
      isLoading={isLoading}
      address={address}
      allOtherPerils={allOtherPerils}
      carportAmount={carportAmount}
      carportYearBuilt={carportYearBuilt}
      coverageI={propertyValue}
      coverageII={otherStructurePercent}
      coverageIII={personalPropertyPercent}
      coverageIV={lossOfUse}
      coverageV={personalLiability}
      coverageVI={medicalPayments}
      hurricane={hurricane}
      mold={mold}
      name={fullName}
      parametricExpenses={parametricExpenses}
      startDate={startDate}
    />
  );
}
