import { AnimatedLoader } from './AnimatedLoader';
import { Close } from './Close';
import { LeftArrow } from './LeftArrow';
import { StyledSVGProps } from './StyledSVG';
import { TrashCan } from './TrashCan';

export const SVG_COMPONENTS_TABLE = {
  AnimatedLoader: AnimatedLoader,
  Close: Close,
  TrashCan: TrashCan,
  LeftArrow: LeftArrow,
};

interface IProps extends StyledSVGProps {
  name: keyof typeof SVG_COMPONENTS_TABLE;
}

export function SVG({ name, ...props }: IProps) {
  const Component = SVG_COMPONENTS_TABLE[name];
  return <Component {...props} />;
}
