import {
  Box,
  BoxProps,
  Skeleton,
  Typography,
  TypographyProps,
} from '@mui/material';

import { FontWeight } from '@/config/theme/typography';

const labelStyle: TypographyProps = {
  fontSize: '0.8125rem',
  textTransform: 'uppercase',
  color: 'grey.70',
};

const valueStyle: TypographyProps = {
  fontSize: '1.0625rem',
  fontWeight: FontWeight.semibold,
  color: 'grey.90',
};

interface IProps extends BoxProps {
  label?: string;
  value?: string;
}

export function HeaderInfo({ label = '---', value = '---', ...props }: IProps) {
  return (
    <Box {...props}>
      <Typography {...labelStyle}>{label}</Typography>
      <Typography {...valueStyle}>{value || <Skeleton />}</Typography>
    </Box>
  );
}
