import {
  HybridHomeownersContract,
  HybridHomeownersQuote,
} from '@/graphql/generated';

export const mockResponse: {
  getCustomerContract: HybridHomeownersContract & {
    quotes: HybridHomeownersQuote[];
  };
} = {
  getCustomerContract: {
    __typename: 'HybridHomeownersContract',
    _id: '3refsd-9dds-342csd-3dsd',
    customerCounterparty: {
      __typename: 'User',
      profile: {
        firstName: 'John',
        lastName: 'Doe',
      },
    },
    quotes: [
      {
        _id: '8ba106d7-9d',
        coverageStructure: {
          startDate: '2023-01-01T12:00:00.000Z',
          allOtherPerils: {
            unit: 'USD',
            vector: 10000,
          },
          propertyValue: {
            vector: 400000,
          },
          otherStructuresPercentage: {
            unit: '%',
            vector: 0.02,
          },
          personalPropertyPercentage: {
            unit: '%',
            vector: 0.25,
          },
          lossOfUse: {
            unit: 'USD',
            vector: 35000,
          },
          personalLiability: {
            unit: 'USD',
            vector: 5000,
          },
          medicalPayments: {
            unit: 'USD',
            vector: 5000,
          },
          roofAdjustmentLimit: {
            unit: 'USD',
            vector: 25000,
          },
          hurricaneDeductible: {
            unit: 'USD',
            vector: 15000,
          },
          mold: {
            unit: 'USD',
            vector: 15000,
          },
          carportAmount: 10000,
          carportYearBuilt: 2010,
          property: {
            address: {
              __typename: 'Address',
              address1: '2008 Jellyfish Hwy',
              address2: '',
              city: 'Key West',
              state: 'FL',
              zipcode: '33040',
            },
          },
        },
        prices: {
          fullPremium: {
            __typename: 'UnitVectorFloat',
            vector: 1108.78,
            unit: 'USD',
          },
          monthlyPremium: {
            __typename: 'UnitVectorFloat',
            vector: 142.57,
            unit: 'USD',
          },
        },
      },
    ],
  },
};
