import { Box, Stack, Typography } from '@mui/material';

import { FontWeight } from '@/config/theme/typography';

import { InfoIconButtonToolTip } from '../Button';
interface IProps {
  children?: string | number;
  label?: string;
  subtext?: string;
  size?: 'normal' | 'small';
  tooltip?: string;
}

export function InfoRow({
  children,
  label,
  subtext,
  size = 'normal',
  tooltip,
}: IProps) {
  const fontSize = size === 'normal' ? '1.3125rem' : '1.0625rem';

  return (
    <Box>
      <Typography
        fontSize="0.8125rem"
        textTransform="uppercase"
        sx={{ color: 'grey.70' }}
      >
        {label}
      </Typography>
      <Typography
        fontSize={fontSize}
        fontWeight={FontWeight.semibold}
        sx={{ color: 'grey.90' }}
      >
        {children}
      </Typography>
      {subtext && (
        <Stack alignItems="center" direction="row">
          <Typography fontSize="0.9375rem">{subtext}</Typography>
          {tooltip && (
            <InfoIconButtonToolTip tooltipText={tooltip} sx={{ padding: 0 }} />
          )}
        </Stack>
      )}
    </Box>
  );
}
