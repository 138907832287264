import { Box, Link, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { FontWeight } from '@/config/theme/typography';

export const HEADER_MAX_WIDTH = '960px';
export const HEADER_HEIGHT = '81px';

interface IProps {
  hideQuoteId?: boolean;
  quoteId?: string;
}

export function Header({ hideQuoteId = false, quoteId }: IProps) {
  const { t } = useTranslation();
  return (
    <Box width="100%" overflow="hidden" height={HEADER_HEIGHT}>
      <Box
        alignItems="center"
        borderBottom="1px solid"
        borderColor="grey.50"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        margin="auto"
        maxWidth={HEADER_MAX_WIDTH}
        px={{ xs: 2, md: 0 }}
        py={2.5}
      >
        <Box>
          <Stack alignItems="center" direction="row" spacing={3}>
            {/* Temp logo placeholder */}
            <Box
              sx={{
                width: '75px',
                height: '40px',
                backgroundColor: 'grey.40',
              }}
            />
            {!hideQuoteId && (
              <Typography fontSize="1.0625rems" fontWeight={FontWeight.regular}>
                {`${t('common.labels.quoteId', 'Quote ID')}: ${quoteId || ''}`}
              </Typography>
            )}
          </Stack>
        </Box>
        <Box>
          <Link
            href="#"
            color="secondary"
            fontSize="1rem"
            fontWeight={FontWeight.semibold}
            sx={{ textDecoration: 'none' }}
          >
            {t('components.header.contact', 'Contact your agent')}
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
