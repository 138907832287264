/* eslint-disable @typescript-eslint/no-explicit-any, import/no-named-as-default */
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  EmailAddress: any;
  JSON: any;
  PhoneNumber: any;
  Upload: any;
};

export type AddPropertyInputs = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  state: Scalars['String'];
  zipcode: Scalars['String'];
};

export type Address = {
  __typename?: 'Address';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type AddressInputs = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type Agreements = {
  __typename?: 'Agreements';
  agreeClaimsClient?: Maybe<Scalars['Boolean']>;
  agreeElectronicBroker?: Maybe<Scalars['Boolean']>;
  agreeElectronicClient?: Maybe<Scalars['Boolean']>;
  agreePrivacyPolicy?: Maybe<Scalars['Boolean']>;
  agreeTerrorismDisclosure?: Maybe<Scalars['Boolean']>;
  signNameBroker?: Maybe<Scalars['String']>;
  signNameCustomer?: Maybe<Scalars['String']>;
};

export enum AppType {
  Default = 'DEFAULT',
  Hh = 'HH',
  Rm = 'RM',
  Singlife = 'SINGLIFE',
}

export type Application = {
  __typename?: 'Application';
  agentInformation?: Maybe<ContractUserInformation>;
  agreements?: Maybe<Agreements>;
  approvalsObj?: Maybe<ApprovalsObj>;
  clientInformation?: Maybe<ClientUserInformation>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export enum ApplicationStatus {
  Approved = 'APPROVED',
  AwaitingAgent = 'AWAITING_AGENT',
  AwaitingClient = 'AWAITING_CLIENT',
  Cancelled = 'CANCELLED',
  Draft = 'DRAFT',
  Expired = 'EXPIRED',
  PendingApproval = 'PENDING_APPROVAL',
  Rejected = 'REJECTED',
  Submitted = 'SUBMITTED',
}

export enum ApplicationType {
  Derivatives = 'DERIVATIVES',
  Energy = 'ENERGY',
  Insurance = 'INSURANCE',
}

export type ApprovalsObj = {
  __typename?: 'ApprovalsObj';
  businessType?: Maybe<Scalars['Boolean']>;
  closingDate?: Maybe<Scalars['Boolean']>;
  contractLimit?: Maybe<Scalars['Boolean']>;
  programAggregateLimit?: Maybe<Scalars['Boolean']>;
  stateAndCountyMatch?: Maybe<Scalars['Boolean']>;
};

export type AuthUserPayload = {
  __typename?: 'AuthUserPayload';
  token: Scalars['String'];
  user: User;
};

export enum BusinessType {
  AgricultureForestryFishingAndHunting = 'AGRICULTURE_FORESTRY_FISHING_AND_HUNTING',
  AllOtherAnimalProduction = 'ALL_OTHER_ANIMAL_PRODUCTION',
  AllOtherGrainFarming = 'ALL_OTHER_GRAIN_FARMING',
  AllOtherMiscellaneousCropFarming = 'ALL_OTHER_MISCELLANEOUS_CROP_FARMING',
  AppleOrchards = 'APPLE_ORCHARDS',
  BeefCattleRanchingAndFarming = 'BEEF_CATTLE_RANCHING_AND_FARMING',
  BerryExceptStrawberryFarming = 'BERRY_EXCEPT_STRAWBERRY_FARMING',
  BroilersAndOtherMeatTypeChickenProduction = 'BROILERS_AND_OTHER_MEAT_TYPE_CHICKEN_PRODUCTION',
  CattleFeedlots = 'CATTLE_FEEDLOTS',
  ChickenEggProduction = 'CHICKEN_EGG_PRODUCTION',
  CitrusExceptOrangeGroves = 'CITRUS_EXCEPT_ORANGE_GROVES',
  CornFarming = 'CORN_FARMING',
  CottonFarming = 'COTTON_FARMING',
  CottonGinning = 'COTTON_GINNING',
  CropHarvestingPrimarilyByMachine = 'CROP_HARVESTING_PRIMARILY_BY_MACHINE',
  DairyCattleAndMilkProduction = 'DAIRY_CATTLE_AND_MILK_PRODUCTION',
  DryPeaAndBeanFarming = 'DRY_PEA_AND_BEAN_FARMING',
  DualPurposeCattleRanchingAndFarming = 'DUAL_PURPOSE_CATTLE_RANCHING_AND_FARMING',
  FarmManagementServices = 'FARM_MANAGEMENT_SERVICES',
  FloricultureProduction = 'FLORICULTURE_PRODUCTION',
  FruitAndTreeNutCombinationFarming = 'FRUIT_AND_TREE_NUT_COMBINATION_FARMING',
  FruitAndTreeNutFarming = 'FRUIT_AND_TREE_NUT_FARMING',
  GoatFarming = 'GOAT_FARMING',
  GrapeVineyards = 'GRAPE_VINEYARDS',
  GreenhouseNurseryAndFloricultureProduction = 'GREENHOUSE_NURSERY_AND_FLORICULTURE_PRODUCTION',
  HayFarming = 'HAY_FARMING',
  HogAndPigFarming = 'HOG_AND_PIG_FARMING',
  MushroomProduction = 'MUSHROOM_PRODUCTION',
  NurseryAndTreeProduction = 'NURSERY_AND_TREE_PRODUCTION',
  OilseedAndGrainCombinationFarming = 'OILSEED_AND_GRAIN_COMBINATION_FARMING',
  OilseedAndGrainFarming = 'OILSEED_AND_GRAIN_FARMING',
  OilseedExceptSoybeanFarming = 'OILSEED_EXCEPT_SOYBEAN_FARMING',
  OrangeGroves = 'ORANGE_GROVES',
  Other = 'OTHER',
  PeanutFarming = 'PEANUT_FARMING',
  PostharvestCropActivitiesExceptCottonGinning = 'POSTHARVEST_CROP_ACTIVITIES_EXCEPT_COTTON_GINNING',
  PotatoFarming = 'POTATO_FARMING',
  PoultryAndEggProduction = 'POULTRY_AND_EGG_PRODUCTION',
  PoultryHatcheries = 'POULTRY_HATCHERIES',
  RanchingAndFarming = 'RANCHING_AND_FARMING',
  RiceFarming = 'RICE_FARMING',
  SheepAndGoatFarming = 'SHEEP_AND_GOAT_FARMING',
  SheepFarming = 'SHEEP_FARMING',
  SoybeanFarming = 'SOYBEAN_FARMING',
  StrawberryFarming = 'STRAWBERRY_FARMING',
  SugarcaneFarming = 'SUGARCANE_FARMING',
  SugarBeetFarming = 'SUGAR_BEET_FARMING',
  SupportActivitiesForAnimalProduction = 'SUPPORT_ACTIVITIES_FOR_ANIMAL_PRODUCTION',
  SupportActivitiesForCropProduction = 'SUPPORT_ACTIVITIES_FOR_CROP_PRODUCTION',
  TobaccoFarming = 'TOBACCO_FARMING',
  TreeNutFarming = 'TREE_NUT_FARMING',
  TurkeyProduction = 'TURKEY_PRODUCTION',
  VegetableAndMelonFarming = 'VEGETABLE_AND_MELON_FARMING',
  WheatFarming = 'WHEAT_FARMING',
}

export enum CftcnfaRadio {
  AssetsOfTenMillionOrMore = 'ASSETS_OF_TEN_MILLION_OR_MORE',
  CommodityPoolWithFiveMillion = 'COMMODITY_POOL_WITH_FIVE_MILLION',
  NetWorthOfOneMillionOrMore = 'NET_WORTH_OF_ONE_MILLION_OR_MORE',
}

export type ClientCheckboxes = {
  __typename?: 'ClientCheckboxes';
  CFTCEightBillion?: Maybe<Scalars['Boolean']>;
  CFTCEntityOwnedByAnotherEntity?: Maybe<Scalars['Boolean']>;
  CFTCNaturalGas?: Maybe<Scalars['Boolean']>;
  CFTCSwapParticipant?: Maybe<Scalars['Boolean']>;
  CFTCfederalAgency?: Maybe<Scalars['Boolean']>;
  CFTCfinancialInNature?: Maybe<Scalars['Boolean']>;
  NYDisputes?: Maybe<Scalars['Boolean']>;
  agreeElectronicClientDisclosure?: Maybe<Scalars['Boolean']>;
  assessmentAndUnderstanding?: Maybe<Scalars['Boolean']>;
  governingLawAndJurisdictionNY?: Maybe<Scalars['Boolean']>;
  moneyLaunderingCompliance?: Maybe<Scalars['Boolean']>;
  moneyLaunderingProvideInformation?: Maybe<Scalars['Boolean']>;
  nonReliance?: Maybe<Scalars['Boolean']>;
  sanctionsBeneficialOwners?: Maybe<Scalars['Boolean']>;
  sanctionsCitizenSanctionedCountry?: Maybe<Scalars['Boolean']>;
  sanctionsControlledBySanctionedCountry?: Maybe<Scalars['Boolean']>;
  sanctionsOnSanctionsList?: Maybe<Scalars['Boolean']>;
  statusOfParties?: Maybe<Scalars['Boolean']>;
  waiverOfJuryTrial?: Maybe<Scalars['Boolean']>;
};

export type ClientInfoApplicationInput = {
  CFTCEightBillion: Scalars['Boolean'];
  CFTCEntityOwnedByAnotherEntity: Scalars['Boolean'];
  CFTCEntityOwnedByAnotherEntityName?: InputMaybe<Scalars['String']>;
  CFTCNaturalGas: Scalars['Boolean'];
  CFTCSwapParticipant: Scalars['Boolean'];
  CFTCfederalAgency: Scalars['Boolean'];
  CFTCfinancialInNature: Scalars['Boolean'];
  NYDisputes: Scalars['Boolean'];
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  agreeElectronicClient: Scalars['Boolean'];
  agreeElectronicClientDisclosure: Scalars['Boolean'];
  agreePrivacyPolicy: Scalars['Boolean'];
  assessmentAndUnderstanding: Scalars['Boolean'];
  businessAddress1: Scalars['String'];
  businessAddress2?: InputMaybe<Scalars['String']>;
  businessCity: Scalars['String'];
  businessCountry: Scalars['String'];
  businessDescription: Scalars['Boolean'];
  businessState: Scalars['String'];
  businessTitle: Scalars['String'];
  businessType: BusinessType;
  businessZipcode: Scalars['String'];
  cftcnfaRadio: CftcnfaRadio;
  city: Scalars['String'];
  companyName: Scalars['String'];
  country: Scalars['String'];
  email: Scalars['String'];
  entityType: EntityType;
  firstName: Scalars['String'];
  governingLawAndJurisdictionNY: Scalars['Boolean'];
  lastName: Scalars['String'];
  lei?: InputMaybe<Scalars['String']>;
  leiBool?: InputMaybe<Scalars['Boolean']>;
  moneyLaunderingCompliance: Scalars['Boolean'];
  moneyLaunderingProvideInformation: Scalars['Boolean'];
  nfaId?: InputMaybe<Scalars['String']>;
  nonReliance: Scalars['Boolean'];
  phone: Scalars['String'];
  sanctionsBeneficialOwners: Scalars['Boolean'];
  sanctionsCitizenSanctionedCountry: Scalars['Boolean'];
  sanctionsControlledBySanctionedCountry: Scalars['Boolean'];
  sanctionsOnSanctionsList: Scalars['Boolean'];
  signNameCustomer: Scalars['String'];
  state: Scalars['String'];
  statusOfParties: Scalars['Boolean'];
  taxId: Scalars['String'];
  tradeName: Scalars['String'];
  waiverOfJuryTrial: Scalars['Boolean'];
  zipcode: Scalars['String'];
};

export type ClientUserInformation = {
  __typename?: 'ClientUserInformation';
  CFTCEntityOwnedByAnotherEntityName?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  businessAddress?: Maybe<Address>;
  businessDescription?: Maybe<Scalars['Boolean']>;
  businessTitle?: Maybe<Scalars['String']>;
  businessType?: Maybe<Scalars['String']>;
  cftcnfaRadio?: Maybe<Scalars['String']>;
  checkboxes?: Maybe<ClientCheckboxes>;
  companyName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailAddress']>;
  entityType?: Maybe<EntityType>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  lei?: Maybe<Scalars['String']>;
  leiBool?: Maybe<Scalars['Boolean']>;
  nfaId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  tradeName?: Maybe<Scalars['String']>;
};

export type Contract = {
  _id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerCounterparty?: Maybe<User>;
  quotes?: Maybe<Array<Maybe<Quote>>>;
  salesAgent?: Maybe<User>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum ContractDiscriminatorType {
  DerivativesContract = 'DERIVATIVES_CONTRACT',
  HybridhomeownersContract = 'HYBRIDHOMEOWNERS_CONTRACT',
}

export type ContractUserInformation = {
  __typename?: 'ContractUserInformation';
  address?: Maybe<Address>;
  email?: Maybe<Scalars['EmailAddress']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type CreateClientUserInputs = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  email: Scalars['EmailAddress'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type CreateHhApplicationInputs = {
  diligentSearchUpload: Scalars['String'];
  signatureUpload: Scalars['String'];
};

export type CreateHybridHomeownersQuoteInputs = {
  allOtherPerils: Scalars['Float'];
  animalLiability: Scalars['Boolean'];
  aopDeductible: Scalars['Float'];
  carportAmount?: InputMaybe<Scalars['Float']>;
  carportYearBuilt?: InputMaybe<Scalars['Int']>;
  hurricaneDeductible: UnitVectorFloatInputs;
  limitedFungiLimit: LimitedFungiLimitInterval;
  limitedWaterDamage: Scalars['Float'];
  lossAssessmentAmount: Scalars['Float'];
  lossOfUse: Scalars['Float'];
  medicalPayments: Scalars['Float'];
  mold?: InputMaybe<Scalars['Float']>;
  ordinanceOfLawPercentage: Scalars['Float'];
  otherStructuresPercentage: Scalars['Float'];
  personalInjury: Scalars['Boolean'];
  personalLiability: Scalars['Float'];
  personalPropertyPercentage: Scalars['Float'];
  personalPropertyReplacement: Scalars['Boolean'];
  propertyValue: Scalars['Float'];
  rentedDwelling: Scalars['Boolean'];
  rentedLiability: Scalars['Boolean'];
  requestId: Scalars['String'];
  roofAdjustmentLimit: Scalars['Int'];
  roofDeductible: UnitVectorFloatInputs;
  startDate: Scalars['String'];
  waterBackup: Scalars['Boolean'];
  waterDeductible: Scalars['Float'];
  waterExclusion: Scalars['Boolean'];
};

export type CreateOrgAdminInputs = {
  email: Scalars['EmailAddress'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  phone: Scalars['String'];
};

export type CreateOrganizationInputs = {
  affiliatedTradeAssociations?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  coverAllLocations?: InputMaybe<Scalars['Boolean']>;
  documents?: InputMaybe<Array<OrgDocumentInput>>;
  fein?: InputMaybe<Scalars['String']>;
  hasSurplusLinesLicense?: InputMaybe<Scalars['Boolean']>;
  history?: InputMaybe<OrgHistoryInputs>;
  licensedProducers?: InputMaybe<Array<InputMaybe<OrgLicensedProducerInputs>>>;
  licenses?: InputMaybe<Array<OrgLicenseInput>>;
  locations?: InputMaybe<Array<InputMaybe<OrgLocationInputs>>>;
  name?: InputMaybe<Scalars['String']>;
  producerType?: InputMaybe<OrganizationProducerType>;
  signature?: InputMaybe<Scalars['String']>;
  signingDate?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
  tradeNames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  website?: InputMaybe<Scalars['String']>;
  yearsInBusiness?: InputMaybe<Scalars['Int']>;
};

export type CreateQuoteInputs = {
  collateral?: InputMaybe<Scalars['Int']>;
  coverages?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  dataset: Scalars['String'];
  end: Scalars['String'];
  exhaust?: InputMaybe<Scalars['Float']>;
  lat: Scalars['Float'];
  lc_end?: InputMaybe<Scalars['String']>;
  lc_start?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  locationName: Scalars['String'];
  lon: Scalars['Float'];
  option_type?: InputMaybe<OptionType>;
  start: Scalars['String'];
  strike?: InputMaybe<Scalars['Float']>;
  units: Scalars['String'];
};

export type Dataset = {
  __typename?: 'Dataset';
  dclimateId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export enum DeleteType {
  Hard = 'HARD',
  Soft = 'SOFT',
}

export type DerivativesContract = Contract & {
  __typename?: 'DerivativesContract';
  _id?: Maybe<Scalars['ID']>;
  application?: Maybe<Application>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerCounterparty?: Maybe<User>;
  lifecycleStage?: Maybe<Scalars['String']>;
  quotes?: Maybe<Array<Maybe<Quote>>>;
  salesAgent?: Maybe<User>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DerivativesCoverageStructure = {
  __typename?: 'DerivativesCoverageStructure';
  dataset?: Maybe<Dataset>;
  endDate?: Maybe<Scalars['DateTime']>;
  exhaust?: Maybe<UnitVectorFloat>;
  lcRequired?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<UnitVectorInt>;
  location?: Maybe<Location>;
  programName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  strike?: Maybe<UnitVectorFloat>;
};

export type DerivativesPrices = {
  __typename?: 'DerivativesPrices';
  agentCommission?: Maybe<UnitVectorFloat>;
  arbolCommission?: Maybe<UnitVectorFloat>;
  customerPremium?: Maybe<UnitVectorFloat>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  riskCapitalPremium?: Maybe<UnitVectorFloat>;
};

export type DerivativesQuote = Quote & {
  __typename?: 'DerivativesQuote';
  _id?: Maybe<Scalars['ID']>;
  coverageStructure?: Maybe<DerivativesCoverageStructure>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  customerCounterparty?: Maybe<User>;
  prices?: Maybe<DerivativesPrices>;
  requestId?: Maybe<Scalars['String']>;
  salesAgent?: Maybe<User>;
  salesPlatform?: Maybe<SalesPlatform>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type Document = {
  __typename?: 'Document';
  name?: Maybe<Scalars['String']>;
  type?: Maybe<DocumentType>;
  upload?: Maybe<Scalars['String']>;
};

export enum DocumentType {
  AgentLicense = 'AGENT_LICENSE',
}

export enum EntityType {
  Corporation = 'CORPORATION',
  LimitedLiabilityCompany = 'LIMITED_LIABILITY_COMPANY',
  Partnership = 'PARTNERSHIP',
  SoleProprietorship = 'SOLE_PROPRIETORSHIP',
  SCorporation = 'S_CORPORATION',
}

export type Feature = {
  __typename?: 'Feature';
  geometry?: Maybe<Geometry>;
  type?: Maybe<Scalars['String']>;
};

export type File = {
  __typename?: 'File';
  _id?: Maybe<Scalars['ID']>;
  bucketName?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
};

export type FileStorageInputs = {
  bucketName: S3Bucket;
  folder?: InputMaybe<Scalars['String']>;
};

export type Geometry = {
  __typename?: 'Geometry';
  coordinates?: Maybe<Array<Maybe<Scalars['Float']>>>;
  properties?: Maybe<Properties>;
  type?: Maybe<Scalars['String']>;
};

export enum GeometryType {
  Point = 'POINT',
  Polygon = 'POLYGON',
}

export type HybridHomeownersApplication = {
  __typename?: 'HybridHomeownersApplication';
  premium?: Maybe<UnitVectorFloat>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
};

export type HybridHomeownersContract = Contract & {
  __typename?: 'HybridHomeownersContract';
  _id?: Maybe<Scalars['ID']>;
  application?: Maybe<HybridHomeownersApplication>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerCounterparty?: Maybe<User>;
  lastEditedAt?: Maybe<Scalars['DateTime']>;
  lifecycleStage?: Maybe<Scalars['String']>;
  quotes?: Maybe<Array<Maybe<Quote>>>;
  salesAgent?: Maybe<User>;
  startDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HybridHomeownersCoverageStructure = {
  __typename?: 'HybridHomeownersCoverageStructure';
  allOtherPerils?: Maybe<UnitVectorFloat>;
  animalLiability?: Maybe<Scalars['Boolean']>;
  aopDeductible?: Maybe<UnitVectorFloat>;
  carportAmount?: Maybe<Scalars['Int']>;
  carportYearBuilt?: Maybe<Scalars['Int']>;
  hurricaneDeductible?: Maybe<UnitVectorFloat>;
  limitedFungiLimit?: Maybe<LimitedFungiLimitInterval>;
  limitedWaterDamage?: Maybe<UnitVectorFloat>;
  lossAssessmentAmount?: Maybe<UnitVectorFloat>;
  lossOfUse?: Maybe<UnitVectorFloat>;
  medicalPayments?: Maybe<UnitVectorFloat>;
  mold?: Maybe<UnitVectorFloat>;
  ordinanceOfLawPercentage?: Maybe<UnitVectorFloat>;
  otherStructuresPercentage?: Maybe<UnitVectorFloat>;
  personalInjury?: Maybe<Scalars['Boolean']>;
  personalLiability?: Maybe<UnitVectorFloat>;
  personalPropertyPercentage?: Maybe<UnitVectorFloat>;
  personalPropertyReplacement?: Maybe<Scalars['Boolean']>;
  property?: Maybe<PropertyQuoteDetails>;
  propertyRef?: Maybe<Scalars['ID']>;
  propertyValue?: Maybe<UnitVectorFloat>;
  rentedDwelling?: Maybe<Scalars['Boolean']>;
  rentedLiability?: Maybe<Scalars['Boolean']>;
  roofAdjustmentLimit?: Maybe<UnitVectorFloat>;
  roofDeductible?: Maybe<UnitVectorFloat>;
  startDate?: Maybe<Scalars['String']>;
  waterBackup?: Maybe<Scalars['Boolean']>;
  waterDeductible?: Maybe<UnitVectorFloat>;
  waterExclusion?: Maybe<Scalars['Boolean']>;
};

export type HybridHomeownersPrices = {
  __typename?: 'HybridHomeownersPrices';
  fullPremium?: Maybe<UnitVectorFloat>;
  monthlyPremium?: Maybe<UnitVectorFloat>;
};

export type HybridHomeownersQuote = Quote & {
  __typename?: 'HybridHomeownersQuote';
  _id?: Maybe<Scalars['ID']>;
  coverageStructure?: Maybe<HybridHomeownersCoverageStructure>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  customerCounterparty?: Maybe<User>;
  prices?: Maybe<HybridHomeownersPrices>;
  requestId?: Maybe<Scalars['String']>;
  salesAgent?: Maybe<User>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InviteOrgAgentInputs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type InviteResponse = {
  __typename?: 'InviteResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export enum LifecycleStage {
  AwaitingApplication = 'AWAITING_APPLICATION',
  AwaitingEvaluation = 'AWAITING_EVALUATION',
  Cancelled = 'CANCELLED',
  Evaluated = 'EVALUATED',
  InProgress = 'IN_PROGRESS',
  PolicyIssued = 'POLICY_ISSUED',
  PreApplication = 'PRE_APPLICATION',
}

export enum LimitedFungiLimitInterval {
  FifteenToThirtyThousand = 'FIFTEEN_TO_THIRTY_THOUSAND',
  FiftyThousand = 'FIFTY_THOUSAND',
  TenToTwentyThousand = 'TEN_TO_TWENTY_THOUSAND',
  TwentyFiveToFiftyThousand = 'TWENTY_FIVE_TO_FIFTY_THOUSAND',
}

export type Location = {
  __typename?: 'Location';
  feature?: Maybe<Feature>;
  name?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addAppPermission?: Maybe<User>;
  addProperty?: Maybe<Property>;
  attachClientToQuote?: Maybe<Quote>;
  authorize?: Maybe<AuthUserPayload>;
  changePassword?: Maybe<AuthUserPayload>;
  clientApplicationVerification?: Maybe<Contract>;
  confirmUser?: Maybe<AuthUserPayload>;
  createApplication?: Maybe<Contract>;
  createClientUser?: Maybe<User>;
  createHHApplication?: Maybe<Contract>;
  createHHQuote?: Maybe<Quote>;
  createOrgAdmin?: Maybe<User>;
  createOrganization?: Maybe<Organization>;
  createProspectRequest?: Maybe<ProspectRequest>;
  createQuote?: Maybe<Array<Maybe<Quote>>>;
  deleteProperty?: Maybe<Property>;
  deleteProspectRequest?: Maybe<ProspectRequest>;
  deleteQuote?: Maybe<Quote>;
  deleteQuotes?: Maybe<Array<Maybe<Quote>>>;
  forgotPassword?: Maybe<Scalars['Boolean']>;
  getSignedUrl: Scalars['String'];
  inviteOrgAgent?: Maybe<InviteResponse>;
  login?: Maybe<AuthUserPayload>;
  registerOrgAgent?: Maybe<User>;
  registerUser?: Maybe<Scalars['Boolean']>;
  resendConfirm?: Maybe<Scalars['Boolean']>;
  resetPassword?: Maybe<AuthUserPayload>;
  reviewProperty?: Maybe<ReviewPropertyResponse>;
  saveHHQuotes?: Maybe<Array<Maybe<Quote>>>;
  saveQuote?: Maybe<Quote>;
  underwritingCheckHH?: Maybe<ReviewUnderwritingCheck>;
  updateClientEmail?: Maybe<User>;
  updateOrganizationContact?: Maybe<Organization>;
  updateOrganizationLocationAddress?: Maybe<Organization>;
  updateProperty?: Maybe<Property>;
  updateProspectRequest?: Maybe<ProspectRequest>;
  updateUser?: Maybe<User>;
  uploadFile: File;
};

export type MutationAddAppPermissionArgs = {
  appType: AppType;
  user: Scalars['String'];
};

export type MutationAddPropertyArgs = {
  client: Scalars['String'];
  input: AddPropertyInputs;
};

export type MutationAttachClientToQuoteArgs = {
  client: Scalars['String'];
  quote: Scalars['String'];
};

export type MutationAuthorizeArgs = {
  token: Scalars['String'];
};

export type MutationChangePasswordArgs = {
  appType?: AppType;
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};

export type MutationClientApplicationVerificationArgs = {
  contract: Scalars['String'];
  input: ClientInfoApplicationInput;
};

export type MutationConfirmUserArgs = {
  token: Scalars['String'];
};

export type MutationCreateApplicationArgs = {
  client: Scalars['String'];
  quotes: Array<Scalars['String']>;
};

export type MutationCreateClientUserArgs = {
  appType?: InputMaybe<AppType>;
  input: CreateClientUserInputs;
};

export type MutationCreateHhApplicationArgs = {
  client: Scalars['String'];
  input: CreateHhApplicationInputs;
  quotes: Array<Scalars['String']>;
};

export type MutationCreateHhQuoteArgs = {
  client: Scalars['String'];
  input: CreateHybridHomeownersQuoteInputs;
  property: Scalars['String'];
};

export type MutationCreateOrgAdminArgs = {
  inputs: CreateOrgAdminInputs;
  org: Scalars['String'];
};

export type MutationCreateOrganizationArgs = {
  appType: AppType;
  input: CreateOrganizationInputs;
};

export type MutationCreateProspectRequestArgs = {
  clientId: Scalars['String'];
};

export type MutationCreateQuoteArgs = {
  input: CreateQuoteInputs;
  programType: ProgramType;
};

export type MutationDeletePropertyArgs = {
  property: Scalars['String'];
};

export type MutationDeleteProspectRequestArgs = {
  requestProspect: Scalars['String'];
};

export type MutationDeleteQuoteArgs = {
  deleteType?: InputMaybe<DeleteType>;
  quote: Scalars['String'];
  quoteType: QuoteDiscriminatorType;
};

export type MutationDeleteQuotesArgs = {
  deleteType: DeleteType;
  quoteType: QuoteDiscriminatorType;
  quotes: Array<Scalars['String']>;
};

export type MutationForgotPasswordArgs = {
  appType?: AppType;
  email: Scalars['EmailAddress'];
};

export type MutationGetSignedUrlArgs = {
  file: Scalars['String'];
};

export type MutationInviteOrgAgentArgs = {
  inputs: InviteOrgAgentInputs;
  org?: InputMaybe<Scalars['String']>;
};

export type MutationLoginArgs = {
  appType?: AppType;
  email: Scalars['EmailAddress'];
  password: Scalars['String'];
};

export type MutationRegisterOrgAgentArgs = {
  inputs: RegisterOrgAgentInputs;
  invitationCode: Scalars['String'];
};

export type MutationRegisterUserArgs = {
  appType?: AppType;
  input: RegisterUserInputs;
};

export type MutationResendConfirmArgs = {
  appType?: AppType;
};

export type MutationResetPasswordArgs = {
  appType?: AppType;
  password: Scalars['String'];
  token: Scalars['String'];
};

export type MutationReviewPropertyArgs = {
  property: Scalars['String'];
};

export type MutationSaveHhQuotesArgs = {
  client?: InputMaybe<Scalars['String']>;
  quoteIds: Array<Scalars['String']>;
};

export type MutationSaveQuoteArgs = {
  client?: InputMaybe<Scalars['String']>;
  quote: Scalars['String'];
};

export type MutationUnderwritingCheckHhArgs = {
  client: Scalars['String'];
  input: UnderwritingCheckHhInputs;
  property: Scalars['String'];
};

export type MutationUpdateClientEmailArgs = {
  client: Scalars['String'];
  email: Scalars['EmailAddress'];
};

export type MutationUpdateOrganizationContactArgs = {
  contact: Scalars['String'];
  input: UpdateOrganizationContactInputs;
  organization: Scalars['String'];
};

export type MutationUpdateOrganizationLocationAddressArgs = {
  input: AddressInputs;
  location: Scalars['String'];
  organization: Scalars['String'];
};

export type MutationUpdatePropertyArgs = {
  input: UpdatePropertyInputs;
  property: Scalars['String'];
};

export type MutationUpdateProspectRequestArgs = {
  input: UpdateProspectRequestInputs;
  requestProspect: Scalars['String'];
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInputs;
};

export type MutationUploadFileArgs = {
  file: Scalars['Upload'];
  input: FileStorageInputs;
};

export enum OptionType {
  Call = 'CALL',
  Put = 'PUT',
}

export type OrgContact = {
  __typename?: 'OrgContact';
  _id?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  type?: Maybe<OrgContactType>;
};

export type OrgContactInputs = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<OrgContactType>;
};

export enum OrgContactType {
  Accounting = 'Accounting',
  Primary = 'Primary',
}

export type OrgDocument = {
  __typename?: 'OrgDocument';
  name?: Maybe<Scalars['String']>;
  type?: Maybe<OrgDocumentType>;
  upload?: Maybe<Scalars['String']>;
};

export type OrgDocumentInput = {
  name: Scalars['String'];
  type: OrgDocumentType;
  upload: Scalars['String'];
};

export enum OrgDocumentType {
  AgencyEo = 'AGENCY_EO',
  AgencyLicense = 'AGENCY_LICENSE',
  AgencyW9 = 'AGENCY_W9',
  AgentLicense = 'AGENT_LICENSE',
}

export type OrgHistory = {
  __typename?: 'OrgHistory';
  bankruptcy?: Maybe<Scalars['Boolean']>;
  bankruptcyExplanation?: Maybe<Scalars['String']>;
  carryFidelityBond?: Maybe<Scalars['Boolean']>;
  dishonesty?: Maybe<Scalars['Boolean']>;
  dishonestyExplanation?: Maybe<Scalars['String']>;
  felonyConviction?: Maybe<Scalars['Boolean']>;
  felonyExplanation?: Maybe<Scalars['String']>;
  lawsuits?: Maybe<Scalars['Boolean']>;
  lawsuitsExplanation?: Maybe<Scalars['String']>;
  licenseRevoked?: Maybe<Scalars['Boolean']>;
  licenseRevokedExplanation?: Maybe<Scalars['String']>;
  maintainedInTrustAccount?: Maybe<Scalars['Boolean']>;
  premiumVolumes?: Maybe<Array<Maybe<OrgPremiumVolume>>>;
};

export type OrgHistoryInputs = {
  bankruptcy?: InputMaybe<Scalars['Boolean']>;
  bankruptcyExplanation?: InputMaybe<Scalars['String']>;
  carryFidelityBond?: InputMaybe<Scalars['Boolean']>;
  dishonesty?: InputMaybe<Scalars['Boolean']>;
  dishonestyExplanation?: InputMaybe<Scalars['String']>;
  felonyConviction?: InputMaybe<Scalars['Boolean']>;
  felonyExplanation?: InputMaybe<Scalars['String']>;
  lawsuits?: InputMaybe<Scalars['Boolean']>;
  lawsuitsExplanation?: InputMaybe<Scalars['String']>;
  licenseRevoked?: InputMaybe<Scalars['Boolean']>;
  licenseRevokedExplanation?: InputMaybe<Scalars['String']>;
  maintainedInTrustAccount?: InputMaybe<Scalars['Boolean']>;
  premiumVolumes?: InputMaybe<Array<InputMaybe<OrgPremiumVolumeInputs>>>;
};

export type OrgLicenseInput = {
  upload: Scalars['String'];
  userId: Scalars['String'];
};

export type OrgLicensedProducer = {
  __typename?: 'OrgLicensedProducer';
  _id?: Maybe<Scalars['ID']>;
  dateOfBirth?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type OrgLicensedProducerInputs = {
  dateOfBirth?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type OrgLocation = {
  __typename?: 'OrgLocation';
  _id?: Maybe<Scalars['ID']>;
  address?: Maybe<Address>;
  contacts?: Maybe<Array<Maybe<OrgContact>>>;
  primaryLocation?: Maybe<Scalars['Boolean']>;
  type?: Maybe<OrgLocationType>;
};

export type OrgLocationInputs = {
  address?: InputMaybe<AddressInputs>;
  contacts?: InputMaybe<Array<InputMaybe<OrgContactInputs>>>;
  primaryLocation?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<OrgLocationType>;
};

export enum OrgLocationType {
  Mailing = 'Mailing',
  Physical = 'Physical',
}

export type OrgPremiumVolume = {
  __typename?: 'OrgPremiumVolume';
  _id?: Maybe<Scalars['ID']>;
  commercialPCPersonalLines?: Maybe<UnitVectorFloat>;
  personalPCPersonalLines?: Maybe<UnitVectorFloat>;
  recordedAt?: Maybe<Scalars['DateTime']>;
  totalPremium?: Maybe<UnitVectorFloat>;
  year?: Maybe<Scalars['Int']>;
};

export type OrgPremiumVolumeInputs = {
  commercialPCPersonalLines?: InputMaybe<UnitVectorFloatInputs>;
  personalPCPersonalLines?: InputMaybe<UnitVectorFloatInputs>;
  recordedAt?: InputMaybe<Scalars['DateTime']>;
  totalPremium?: InputMaybe<UnitVectorFloatInputs>;
  year?: InputMaybe<Scalars['Int']>;
};

export type OrgSignatureDetails = {
  __typename?: 'OrgSignatureDetails';
  signature?: Maybe<Scalars['String']>;
  signingDate?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
};

export type Organization = {
  __typename?: 'Organization';
  _id?: Maybe<Scalars['ID']>;
  affiliatedTradeAssociations?: Maybe<Array<Maybe<Scalars['String']>>>;
  coverAllLocations?: Maybe<Scalars['Boolean']>;
  fein?: Maybe<Scalars['String']>;
  hasSurplusLinesLicense?: Maybe<Scalars['Boolean']>;
  history?: Maybe<OrgHistory>;
  licensedProducers?: Maybe<Array<Maybe<OrgLicensedProducer>>>;
  locations?: Maybe<Array<Maybe<OrgLocation>>>;
  name?: Maybe<Scalars['String']>;
  producerType?: Maybe<OrganizationProducerType>;
  signatureDetails?: Maybe<OrgSignatureDetails>;
  team?: Maybe<Array<Team>>;
  tradeNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Scalars['String']>;
  yearsInBusiness?: Maybe<Scalars['Int']>;
};

export enum OrganizationProducerType {
  Network = 'Network',
  Retailer = 'Retailer',
  Wholesaler = 'Wholesaler',
}

export type PaginatedContractPayload = {
  __typename?: 'PaginatedContractPayload';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Contract>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type PaginatedPropertyPayload = {
  __typename?: 'PaginatedPropertyPayload';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Property>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type PaginatedQuotePayload = {
  __typename?: 'PaginatedQuotePayload';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Quote>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type PaginatedUserPayload = {
  __typename?: 'PaginatedUserPayload';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<User>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type PaginationInputs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<SortDirection>;
};

export enum ProgramType {
  CustomExcessRain = 'CUSTOM_EXCESS_RAIN',
  CustomLowRain = 'CUSTOM_LOW_RAIN',
  Grp = 'GRP',
  Xsr = 'XSR',
}

export type Properties = {
  __typename?: 'Properties';
  name?: Maybe<Scalars['String']>;
};

export type Property = {
  __typename?: 'Property';
  _id?: Maybe<Scalars['ID']>;
  address?: Maybe<PropertyAddress>;
  client?: Maybe<User>;
  createdAt?: Maybe<Scalars['DateTime']>;
  specs?: Maybe<ProperySpecs>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PropertyAddress = {
  __typename?: 'PropertyAddress';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type PropertyQuoteDetails = {
  __typename?: 'PropertyQuoteDetails';
  address?: Maybe<Address>;
  propertyRef?: Maybe<Scalars['ID']>;
  specs?: Maybe<PropertyQuoteSpecs>;
};

export type PropertyQuoteSpecs = {
  __typename?: 'PropertyQuoteSpecs';
  burglarAlarm?: Maybe<Scalars['Boolean']>;
  construction?: Maybe<Scalars['String']>;
  fireAlarm?: Maybe<Scalars['Boolean']>;
  roofMaterial?: Maybe<Scalars['String']>;
  roofYear?: Maybe<Scalars['Int']>;
  seasonalProperty?: Maybe<Scalars['Boolean']>;
  sprinklerSystem?: Maybe<Scalars['Boolean']>;
  yearBuilt?: Maybe<Scalars['Int']>;
};

export type ProperySpecs = {
  __typename?: 'ProperySpecs';
  bcegCode?: Maybe<Scalars['Int']>;
  burglarAlarm?: Maybe<Scalars['Boolean']>;
  construction?: Maybe<ConstructionType>;
  constructionYear?: Maybe<Scalars['Int']>;
  county?: Maybe<Scalars['String']>;
  coverageLimitMin?: Maybe<Scalars['Int']>;
  distanceGreaterThanOneMileFromCoast?: Maybe<Scalars['Boolean']>;
  eligible?: Maybe<Scalars['Boolean']>;
  fireAlarm?: Maybe<Scalars['Boolean']>;
  insuranceScore?: Maybe<Scalars['Int']>;
  priorClaims?: Maybe<Scalars['Int']>;
  propertyAge?: Maybe<Scalars['Int']>;
  protectionClass?: Maybe<Scalars['Int']>;
  roofMaterial?: Maybe<RoofMaterialType>;
  roofYear?: Maybe<Scalars['Int']>;
  seasonalOccupancy?: Maybe<Scalars['Boolean']>;
  seasonalProperty?: Maybe<Scalars['Boolean']>;
  sprinklerSystem?: Maybe<Scalars['Boolean']>;
  territory?: Maybe<Scalars['String']>;
  territoryGroup?: Maybe<Scalars['Int']>;
  woodShingledRoof?: Maybe<Scalars['Boolean']>;
  yearBuilt?: Maybe<Scalars['Int']>;
  zipCode?: Maybe<Scalars['Int']>;
};

export type ProspectRequest = {
  __typename?: 'ProspectRequest';
  _id?: Maybe<Scalars['ID']>;
  agreeToDiligenceSearch?: Maybe<Scalars['Boolean']>;
  brokerAgentId?: Maybe<Scalars['ID']>;
  client?: Maybe<User>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  property?: Maybe<Scalars['JSON']>;
  quotes?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  startDate?: Maybe<Scalars['DateTime']>;
  underWritingChecks?: Maybe<Array<Maybe<UnderWritingCheckOption>>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum ProspectRequestUnderWritingCheckOptionName {
  Businessproperty = 'BUSINESSPROPERTY',
  Commercialproperty = 'COMMERCIALPROPERTY',
  Condemnedproperty = 'CONDEMNEDPROPERTY',
  Divingboard = 'DIVINGBOARD',
  Farmorranch = 'FARMORRANCH',
  Fourormoremortages = 'FOURORMOREMORTAGES',
  Fraternityorsoronity = 'FRATERNITYORSORONITY',
  Homemadeorrebuilt = 'HOMEMADEORREBUILT',
  Misrepresentationarsonorfraud = 'MISREPRESENTATIONARSONORFRAUD',
  Mobileorprebabricated = 'MOBILEORPREBABRICATED',
  Nonhabitational = 'NONHABITATIONAL',
  Underconstruction = 'UNDERCONSTRUCTION',
}

export type Query = {
  __typename?: 'Query';
  currentUser?: Maybe<User>;
  getContract?: Maybe<Contract>;
  getContracts?: Maybe<PaginatedContractPayload>;
  getGRPDates: Array<RiskPeriodDateBlock>;
  getGatewayMetaData?: Maybe<Scalars['JSON']>;
  getOrganization?: Maybe<Organization>;
  getProperties?: Maybe<PaginatedPropertyPayload>;
  getProperty?: Maybe<Property>;
  getProspectRequest?: Maybe<ProspectRequest>;
  getQuote?: Maybe<Quote>;
  getQuotes?: Maybe<PaginatedQuotePayload>;
  getRainfallDataByLocations: Array<RainfallDataByLocation>;
  getUser?: Maybe<User>;
  getUsers?: Maybe<PaginatedUserPayload>;
};

export type QueryGetContractArgs = {
  id: Scalars['String'];
};

export type QueryGetContractsArgs = {
  input?: InputMaybe<PaginationInputs>;
  type: ContractDiscriminatorType;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetGatewayMetaDataArgs = {
  dataset: Scalars['String'];
};

export type QueryGetOrganizationArgs = {
  id: Scalars['String'];
};

export type QueryGetPropertiesArgs = {
  input?: InputMaybe<PaginationInputs>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetPropertyArgs = {
  property: Scalars['String'];
};

export type QueryGetProspectRequestArgs = {
  id: Scalars['String'];
};

export type QueryGetQuoteArgs = {
  id: Scalars['String'];
};

export type QueryGetQuotesArgs = {
  input?: InputMaybe<PaginationInputs>;
  type: QuoteDiscriminatorType;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetRainfallDataByLocationsArgs = {
  asOf?: InputMaybe<Scalars['DateTime']>;
  input: Array<RainfallDataByLocationInput>;
};

export type QueryGetUserArgs = {
  id: Scalars['String'];
};

export type QueryGetUsersArgs = {
  input?: InputMaybe<PaginationInputs>;
};

export type Quote = {
  _id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  customerCounterparty?: Maybe<User>;
  salesAgent?: Maybe<User>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum QuoteDiscriminatorType {
  Derivatives = 'DERIVATIVES',
  Hybridhomeowners = 'HYBRIDHOMEOWNERS',
}

export type RainfallDataByLocation = {
  __typename?: 'RainfallDataByLocation';
  city: Scalars['String'];
  country: Scalars['String'];
  date: Scalars['String'];
  location: Array<Scalars['Float']>;
  rainfall: Scalars['Float'];
  unit: Scalars['String'];
};

export type RainfallDataByLocationInput = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  location: Array<Scalars['Float']>;
  startDate: Scalars['DateTime'];
};

export type RegisterOrgAgentInputs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
};

export type RegisterUserInputs = {
  activationCode?: InputMaybe<Scalars['String']>;
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  companyName?: InputMaybe<Scalars['String']>;
  country: Scalars['String'];
  email: Scalars['EmailAddress'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  phone: Scalars['String'];
  state?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type ReviewPropertyResponse = {
  __typename?: 'ReviewPropertyResponse';
  failedQuestions?: Maybe<Array<Maybe<Scalars['String']>>>;
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type ReviewUnderwritingCheck = {
  __typename?: 'ReviewUnderwritingCheck';
  failureItems?: Maybe<Array<Maybe<Scalars['String']>>>;
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type RiskPeriodDateBlock = {
  __typename?: 'RiskPeriodDateBlock';
  deadlineDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
};

export enum Role {
  Admin = 'admin',
  Agent = 'agent',
  Client = 'client',
  SuperAdmin = 'superAdmin',
}

export enum S3Bucket {
  GeneralFiles = 'GENERAL_FILES',
}

export type SalesPlatform = {
  __typename?: 'SalesPlatform';
  index?: Maybe<UnitHistory>;
  payouts?: Maybe<UnitHistory>;
  relativeStrike?: Maybe<Scalars['Float']>;
};

export type Team = {
  __typename?: 'Team';
  disabledAt?: Maybe<Scalars['DateTime']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedEmail?: Maybe<Scalars['String']>;
  invitedFirstName?: Maybe<Scalars['String']>;
  invitedLastName?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
};

export type UnderwritingCheckHhInputs = {
  businessProperty?: InputMaybe<Scalars['Boolean']>;
  commercialProperty?: InputMaybe<Scalars['Boolean']>;
  condemnedProperty?: InputMaybe<Scalars['Boolean']>;
  divingBoard?: InputMaybe<Scalars['Boolean']>;
  farmOrRanch?: InputMaybe<Scalars['Boolean']>;
  fourOrMoreMortgages?: InputMaybe<Scalars['Boolean']>;
  fraternityOrSorority?: InputMaybe<Scalars['Boolean']>;
  homemadeOrRebuilt?: InputMaybe<Scalars['Boolean']>;
  misrepresentationArsonOrFraud?: InputMaybe<Scalars['Boolean']>;
  mobileOrPrefabricatedHome?: InputMaybe<Scalars['Boolean']>;
  nonHabitational?: InputMaybe<Scalars['Boolean']>;
  underConstruction?: InputMaybe<Scalars['Boolean']>;
};

export type UnitHistory = {
  __typename?: 'UnitHistory';
  history?: Maybe<Array<Maybe<YearVector>>>;
  unit?: Maybe<Scalars['String']>;
};

export type UnitVectorFloat = {
  __typename?: 'UnitVectorFloat';
  unit?: Maybe<Scalars['String']>;
  vector?: Maybe<Scalars['Float']>;
};

export type UnitVectorFloatInputs = {
  unit: Scalars['String'];
  vector: Scalars['Float'];
};

export type UnitVectorInt = {
  __typename?: 'UnitVectorInt';
  unit?: Maybe<Scalars['String']>;
  vector?: Maybe<Scalars['Int']>;
};

export type UnitVectorIntInputs = {
  unit: Scalars['String'];
  vector: Scalars['Int'];
};

export type UpdateOrganizationContactInputs = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type UpdatePropertyInputs = {
  burglarAlarm?: InputMaybe<Scalars['Boolean']>;
  construction?: InputMaybe<ConstructionType>;
  fireAlarm?: InputMaybe<Scalars['Boolean']>;
  roofMaterial?: InputMaybe<RoofMaterialType>;
  roofYear?: InputMaybe<Scalars['Int']>;
  seasonalProperty?: InputMaybe<Scalars['Boolean']>;
  sprinklerSystem?: InputMaybe<Scalars['Boolean']>;
  yearBuilt?: InputMaybe<Scalars['Int']>;
};

export type UpdateProspectRequestInputs = {
  agreeToDiligenceSearch?: InputMaybe<Scalars['Boolean']>;
  property?: InputMaybe<Scalars['JSON']>;
  quotes?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  underWritingChecks?: InputMaybe<
    Array<InputMaybe<UnderWritingCheckOptionInput>>
  >;
};

export type UpdateUserInputs = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  _id?: Maybe<Scalars['ID']>;
  activationCode?: Maybe<Scalars['String']>;
  confirmedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['EmailAddress']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  organization?: Maybe<Organization>;
  permissions?: Maybe<Array<Maybe<UserPermission>>>;
  profile?: Maybe<UserProfile>;
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserPermission = {
  __typename?: 'UserPermission';
  platform?: Maybe<AppType>;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type YearVector = {
  __typename?: 'YearVector';
  vector?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['String']>;
};

export enum ConstructionType {
  Frame = 'Frame',
  Masonry = 'Masonry',
}

export enum RoofMaterialType {
  Other = 'Other',
  TileMetal = 'Tile_Metal',
}

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type UnderWritingCheckOption = {
  __typename?: 'underWritingCheckOption';
  checked: Scalars['Boolean'];
  name: ProspectRequestUnderWritingCheckOptionName;
  value?: Maybe<Scalars['Boolean']>;
};

export type UnderWritingCheckOptionInput = {
  checked: Scalars['Boolean'];
  name: ProspectRequestUnderWritingCheckOptionName;
  value?: InputMaybe<Scalars['Boolean']>;
};

export type AuthorizeMutationVariables = Exact<{
  token: Scalars['String'];
}>;

export type AuthorizeMutation = {
  __typename?: 'Mutation';
  authorize?: {
    __typename?: 'AuthUserPayload';
    token: string;
    user: {
      __typename: 'User';
      _id?: string | null;
      confirmedAt?: any | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      email?: any | null;
      role?: Role | null;
      organization?: {
        __typename?: 'Organization';
        _id?: string | null;
      } | null;
      profile?: {
        __typename: 'UserProfile';
        firstName?: string | null;
        lastName?: string | null;
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        companyName?: string | null;
        country?: string | null;
        phone?: string | null;
        state?: string | null;
        zipcode?: string | null;
      } | null;
    };
  } | null;
};

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename: 'User';
    _id?: string | null;
    confirmedAt?: any | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    email?: any | null;
    role?: Role | null;
    organization?: { __typename?: 'Organization'; _id?: string | null } | null;
    profile?: {
      __typename: 'UserProfile';
      firstName?: string | null;
      lastName?: string | null;
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      companyName?: string | null;
      country?: string | null;
      phone?: string | null;
      state?: string | null;
      zipcode?: string | null;
    } | null;
  } | null;
};

export type UserFragmentFragment = {
  __typename: 'User';
  _id?: string | null;
  confirmedAt?: any | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email?: any | null;
  role?: Role | null;
  organization?: { __typename?: 'Organization'; _id?: string | null } | null;
  profile?: {
    __typename: 'UserProfile';
    firstName?: string | null;
    lastName?: string | null;
    address1?: string | null;
    address2?: string | null;
    city?: string | null;
    companyName?: string | null;
    country?: string | null;
    phone?: string | null;
    state?: string | null;
    zipcode?: string | null;
  } | null;
};

export const UserFragment = gql`
  fragment userFragment on User {
    __typename
    _id
    confirmedAt
    createdAt
    updatedAt
    email
    role
    organization {
      _id
    }
    profile {
      __typename
      firstName
      lastName
      address1
      address2
      city
      companyName
      country
      firstName
      lastName
      phone
      state
      zipcode
    }
  }
`;
export const AuthorizeDocument = gql`
  mutation authorize($token: String!) {
    authorize(token: $token) {
      token
      user {
        ...userFragment
      }
    }
  }
  ${UserFragment}
`;
export const CurrentUserDocument = gql`
  query currentUser {
    currentUser {
      ...userFragment
    }
  }
  ${UserFragment}
`;
