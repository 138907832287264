import { format as dateFormatFunc } from 'date-fns';

import i18n from '@/config/i18n';
import { Property, PropertyQuoteDetails } from '@/graphql/generated';

export enum DATE_FORMATS {
  DEFAULT = 'MM/dd/yyyy',
  SHORT_MONTH_DAY_FULL_YEAR = 'MMM d, yyyy',
  FULL_MONTH_DAY_FULL_YEAR = 'MMMM d, yyyy',
}

export function formatDate(
  date: string | Date,
  format: keyof typeof DATE_FORMATS = 'DEFAULT',
) {
  return dateFormatFunc(
    typeof date === 'string' ? new Date(date) : date,
    DATE_FORMATS[format],
  );
}

export function formatAddress(
  address: Property['address'] | PropertyQuoteDetails['address'],
) {
  const { address1, address2, city, state, zipcode } = address || {};

  return `${address1 || ''}${address2 ? ` ${address2}` : ''}, ${city || ''}, ${
    state || ''
  } ${zipcode || ''}`;
}

export function formatAddressMultiLine(address: Property['address']) {
  const { address1, address2, city, state, zipcode } = address || {};

  return [
    address1 || '',
    address2 || '',
    `${city || ''}, ${state || ''} ${zipcode || ''}`,
  ];
}

export function formatPhoneNumber(value?: string) {
  if (!value) return '';
  return value.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') || '';
}

export function formatCurrency(value?: number | string) {
  if (!value) return '';

  return i18n.t('common.currency.USD', '{{value, currency(currency: USD)}}', {
    value: value,
  });
}
