import { RouteObject } from 'react-router-dom';

import { DefaultLayout } from '@/components/Layouts/DefaultLayout';
import { ProtectedRouteCheck } from '@/components/Router/ProtectedRouteCheck';
import { UnprotectedRouteCheck } from '@/components/Router/UnprotectedRouteCheck';
import { CustomerSignaturePageContainer } from '@/pages/CustomerSignaturePage/CustomerSignaturePageContainer';
import { CustomerTouchpointPage } from '@/pages/CustomerTouchpointPage/CustomerTouchpointPage';
import { LandingPageContainer } from '@/pages/LandingPage/LandingPageContainer';
import { NotFoundPage } from '@/pages/NotFoundPage';
import { ReviewQuotePageContainer } from '@/pages/ReviewQuotePage/ReviewQuotePageContainer';

export const routes: RouteObject[] = [
  {
    // - Unprotected Routes
    element: <UnprotectedRouteCheck />,
    children: [],
  },
  {
    //- Protected Routes
    element: <ProtectedRouteCheck />,
    children: [],
  },

  // - No protected or unprotected routes
  {
    path: '/customer-email-touchpoint',
    element: <CustomerTouchpointPage />,
  },
  {
    element: <DefaultLayout />,
    children: [
      {
        path: '/',
        element: <LandingPageContainer />,
      },
      {
        path: '/review-quote',
        element: <ReviewQuotePageContainer />,
      },
      {
        path: '/add-signature',
        element: <CustomerSignaturePageContainer />,
      },
      {
        // -- Not Found
        path: '*',
        element: <NotFoundPage />,
      },
    ],
  },
];
