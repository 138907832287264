import { formatCurrency } from '@/helpers/formatters';

export function percentageOfFormatter(
  percent?: string | number,
  value?: string | number,
) {
  if (!percent || !value) {
    return undefined;
  }

  const currencyFormat = formatCurrency(Number(percent) * Number(value));
  const percentageFormat = (Number(percent) * 100)
    .toFixed(2)
    .replace(/\.?0+$/, '');

  return `${currencyFormat} (${percentageFormat}%)`;
}
